.node-type-ian-newsletters {
  .field-name-field-newsletter-pdf {
    .field-item {
      margin-top: 2rem;

      span.file {
        background-color: #016893;
        padding: 0.8rem;
        color: #fff;

        a:hover {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}
