.view--featured-content--slider {
	width: 100%;
	.orbit-slide {
		@include breakpoint(large) {
			padding: 0 6rem;
		}
		> .row {
			overflow: hidden;
			max-width: 75em;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.views-row {
		&:nth-child(odd) {
			background: $dark-gray;
		}
		a.button {
			margin-top: 2rem;
		}
	}
}
