.paragraph__header {
	width: 100%;
}

.paragraph--paragraph-with-link__link a {
	@include opensans_bold;
	@include transition();
	@include clearfix;
	display: block;
	text-align: right;
	margin-bottom: 2rem;
	color: $blue;
  &:visited {
	  color: $blue;
  }
	&:hover {
		color: $blue-hover;
		text-decoration: underline;
	}
}
.paragraph--paragraph-with-image {
	text-align: left;
	overflow: hidden;
	@include breakpoint(medium down) {
		.float-left, .float-right {
			float: none !important;
		}
	}
	@include breakpoint(medium) {
		.field-type-text-long {
			ul {
				list-style-position: inside;
				margin-left: 2rem;
			}
		}
	}
}
.paragraph--paragraph-with-image__image {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 2rem;
	width: 100%;
	@include breakpoint(medium) {
		&.float-right {
			padding-left: 2rem;
		}
		&.float-left {
			padding-right: 2rem;
		}
	}
}

.paragraph--list-links {
	ul {
		li {
			@include opensans_bold;
			font-size: 1.25rem;
		}
	}
}

.paragraph--media {
	.columns {
		margin-bottom: 2rem;
	}
}

.paragraph--featured-links {
	padding-bottom: 0;
	padding-left: 2rem;
	padding-right: 2rem;
	@include breakpoint(large) {
		padding-bottom: 2rem;
	}
	.paragraph--featured-link {
		margin-bottom: 2rem;
	}
}

.paragraph--publication {
	@include opensans_bold;
	font-size: $large-font-size;
	.field-name-field-resource, .date {
		font-size: 100%;
		display: inline-block;
	}
	.field-name-field-resource + .date {
		&:before {
			content: ' | ';
		}
	}
}

.node-umces-page {
	.paragraphs-item-featured-work-slider {
		max-width: 75%;
	}
}
