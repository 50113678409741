.campus-map {
	position: relative;
	.campus-map__bg {
		width: 100%;
		height: auto;
	}
	.campus-map__icons {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.map-icon {
		@include transition();
		cursor: pointer;
		border-radius: 100rem;
		position: absolute;
		text-indent: -999999px;
		background: rgba($green, .25);
		padding: .75rem;
		.map-icon__inner {
			border-radius: 100rem;
			height: 1.5rem;
			width: 1.5rem;
			background: $white;
			border: .4rem solid $green;
		}
		&:hover {
			transform: scale(1.5);
		}
		&.is-active {
			cursor: default;
			transform: scale(1.5);
			background: rgba($green-blue, .25);
			.map-icon__inner {
				border: .4rem solid $green-blue;
			}
		}
	}
}

.campus-list--desktop {
	position: relative;
	.views-row {
		@include transition();
		transform: translateY(-.5rem);
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		opacity: 0;
		&.is-active {
			transform: translateY(0);
			transition-delay: .25s;
			position: relative;
			opacity: 1;
			z-index: 10;
		}
	}
}