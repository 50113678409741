.ian-global-footer {
  h4{
    font-family: 'Kanit',sans-serif;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none !important;
    letter-spacing: 1px;
  }
  background-color: $blue;
  margin: 0;
  text-align: center;
  //font-size: 200%;
  padding: 2rem 0;
  .footer-wrapper{
    max-width: 75rem;
    margin: 0 auto;
    padding: 0 1.5rem;
    @include breakpoint(large) {
      display:flex;
      align-items: center;
    }
    @include breakpoint(medium down) {
      > span {
        display: block;
        padding: 0 1.5rem;
      }
      .socialbtns {
        margin: 2rem 0 0;
      }
    }
  }
  .footer-msg{
    font-size: 1.5rem;
    flex: 0 1 auto;
    span:last-child {
      display: inline-block;
      //@include breakpoint(large down) {
      //  display: block;
      //}
      //@include breakpoint(medium down) {
      //}
    }
  }

  ul{
    display: inline;
    //float: right;
  }

  .pane-useful-resources-content-type {
    margin-top:0;
    display: none;
  }

  .text-bold {
    font-weight: bold;
  }


  .useful-resources-list {
    a{
      color:#a7be77;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  .socialbtns{

    a:hover{
      text-decoration:none;
    }
    flex: 0 1 25rem;
    margin-left: 4rem;
    ul &, li &{
      margin: 0;
    }
    ul {
      display: block;
      width: 100%;
      @include breakpoint(25rem down) {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        li, li:not(:first-child) {
          flex: 0 1 calc(50% - 2rem);
          margin-left: 1rem;
          margin-right: 1rem;
          text-align: center;
          a {
            display: block;
            margin: 0 auto;
          }

        }
        li:first-child, li:nth-child(2) {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  //.socialbtns, .socialbtns ul, .socialbtns li {
  //  margin: 0;
  //  padding: 5px;
  //}

  .socialbtns li {
    list-style: none outside none;
    display: inline-block;
    margin-bottom:0;
    &:not(:first-child) {
      margin-left:2em;
    }
  }

  .socialbtns .fa {
    width: 60px;
    height: 60px;
    color: $blue;//#FFF;
    background-color: $white;
    padding-top: 24px;
    border-radius: 50%;
    transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
  }

  .socialbtns .fa:hover {
    transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
  }
  .socialbtns .fa-lg{
    font-size: 2rem;
    line-height: 1rem;
  }

}
