.navigation--social {
	width: 100%;
	@include breakpoint(large) {
		width: auto;
	}
	.menu {
		> li > a.button.secondary {
			margin: 0;
			border-radius: 0;
			line-height: 2rem;
			color: $medium-gray;
			background: transparent;
			padding: 0 .5rem;
			@include breakpoint(large) {
				padding: 0;
				width: 2rem;
				height: 2rem;
				margin: 0 0 0 .5rem;
				color: $white;
				background: $blue;
				&:hover {
					background: scale-color($blue, $lightness: -15%);
				}
			}
			i {
				display: block;
				height: 100%;
				margin: 0;
				&:before {
					font-size: 2rem;
					@include breakpoint(large) {
						font-size: 1.25rem;
						line-height: 2rem;
					}
				}
			}
		}
	}
}