.view-search {
	.view-header, .view-empty {
		@include kanit_bold;
		@include header-size-responsive(h3);
		text-align: center;
	}
	.view-content, .view-header {
		padding: 2rem;
		background: $white;
	}
	.views-row {
		padding: 0 0 1rem 0;
		margin: 0 0 3rem 0;
		border-bottom: 1px solid $light-gray;
		&:last-of-type {
			padding: 0;
			margin: 0;
			border-bottom: none;
		}
	}
	.paragraph--paragraph-with-link__link a {
		margin-top: 2rem
	}
	.date {
		@include opensans_regular;
		font-size: $xsmall-font-size;
		position: absolute;
		margin-top: -1rem;
	}
}