@media print {

	// Override page margins for print to ensure
	// proper whitespace around printed content.
	@page {
		margin: 2cm 1cm 3cm 1cm !important;
	}

	// Force rows to render as blocks rather than flex for print
	// as flexbox introduces printing issues for firefox.
	.row {
		display: block;
	}

	// Hide unnecessary content.
	header.header,
	footer.footer,
	footer.footer--bottom,
	nav, img, .button--print {
		display: none !important;
	}

	// Override styles for print.
	.show-for-print {
		&.h1, &.h3 {
			margin: 0 !important;
			.pane-content {
				margin: 0 !important;
			}
		}
	}

}
