// Styles related to the e-commerce section
$ecommerce-max-width: 900px;
$cart-border-color: #979797;
$price-color: #be1e2d;
$semibold-font-weight: 600;

// Share icon styles
.ian-ecommerce-cart-icon,
.ian-ecommerce-download-icon {
  @include transition();
  display: inline-block;
  height: 46px;
  width: 46px;
  vertical-align: middle;
  border-radius: 4px;
  background-color: $green;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: -99999rem;
  overflow: hidden;

  padding-left: 0 !important;
  padding-right: 0 !important;

  &:hover {
    &::after { display: none;}
  }
}

// Download icon
.ian-ecommerce-download-icon {
  background-image: url(../img/download-white.svg);
}

// Cart icon used in various places
.ian-ecommerce-cart-icon {
  background-image: url(../img/cart-white.svg);

  &.green {
    height: 28px;
    width: 28px;
    background-color: transparent;
    background-image: url(../img/cart-green.svg);
  }
  &.white {
    background-color: transparent;
    height: 28px;
    width: 28px;
  }
}

// Add to cart for nodes button
.ian-ecommerce-add-to-cart-button {
  .dropdown-pane {
    border: 1px $light-gray solid;
    border-radius: 6px;
    padding: 0;
    overflow: hidden;
    //width: auto;

    a {
      display: block;
      color: $body-font-color;
      padding: .5em 1em;
      width: inherit !important;

      &:not(:last-of-type) {
        border-bottom: 1px $light-gray solid;
      }

      &:hover {
        background-color: $green;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

// Cart block button
.ian-ecommerce-cart-block-link.button {
  border-color: $green;
  color: $green;
  background-color: #FFF;
  padding-right: 2.25em;
  padding-left: 2.25em;

  .ian-ecommerce-cart-icon {
    margin-left: 12px;
  }

  &:hover {
    background-color: $green;
    color: #fff;
    padding-right: 2.25em !important;
    padding-left: 2.25em !important;

    &::after {
      display: none;
    }

    .ian-ecommerce-cart-icon {
      background-image: url(../img/cart-white.svg);
    }
  }
}

// Clear selected button
.selected-clear.button {
  border-color: $medium-gray;
  color: $medium-gray2;
  background-color: #FFF;
  padding-right: 2.25em;
  padding-left: 2.25em;

  &:hover {
    border-color: $medium-gray2;
    background-color: $medium-gray2;
    color: #fff;
    padding-right: 2.25em !important;
    padding-left: 2.25em !important;

    &::after {
      display: none;
    }
  }
}

.ian-ecommerce-cart-block-link-small.button {
  border-color: $green;
  color: $green;
  background-color: #FFF;
  padding-left: 5px;
  padding-right: 15px;

  .ian-ecommerce-cart-icon {
    margin-left: 12px;
  }

  &:hover {
    background-color: $green;
    color: #fff;
    padding-left: 5px !important;
    padding-right: 15px !important;

    &::after {
      display: none;
    }

    .ian-ecommerce-cart-icon {
      background-image: url(../img/cart-white.svg);
    }
  }
}

// Cart & Checkout pages
.page-cart,
.page-checkout {
  background-color: rgba(230, 230, 230, 1);

  .messages {
    max-width: $ecommerce-max-width;
  }

  #order-error-message {
    color: $red;
    text-align: center;
    font-weight: bold;
  }

  .region-content > .block > .content {
    margin-left: auto;
    margin-right: auto;
    max-width: $ecommerce-max-width;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #FFF;
    margin-bottom: 4rem;

    > * {
     // border: 1px red solid;
    }
  }

  // turn off breadcrumbs
  .breadcrumbs_white {
    //display: none;
  }

  // page title margin
  .page-title {
    margin-bottom: 4rem;
    display:none;
  }

  // all buttons
  .form-actions {
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: right;
  }
  .form-submit[type="submit"] {
    @extend .button;
    margin-bottom: 0;

    & + .form-submit {
      margin-left: 12px;
    }

    &:hover {
      background-color: $secondary-color;
    }
  }
  .form-cancel,
  input[type="submit"].form-cancel {
    @include transition();
    @include kanit_semibold();
    text-transform: uppercase;
    text-decoration: none !important;
    letter-spacing: 1px;
    position: relative;
    border-radius: 0.15em;
    line-height: 1;
    display: inline-block;
    padding: .9125em 2.5em;
    border: 2px rgba(230, 230, 230, 1) solid;
    color: rgba(74, 74, 74, 1);
    margin-right: 10px;
    vertical-align: top;
    background-color: #FFF;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(230, 230, 230, 1);
      color: rgba(74, 74, 74, 1);
    }
  }
}

.ian-ecommerce-empty {
  min-height: 200px;
  text-align: center;
}

.ian-ecommerce-summary {
  margin-top: 1rem;
}

.ian-ecommerce-cart-contents,
.ian-ecommerce-cart-header {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.35rem;
  padding-bottom: 1.35rem;
  border-color: $cart-border-color;
  display: flex;

  > * {
    margin-left: 2rem;
  }

  .ian-ecommerce-cart-product {
    flex-grow: 1;
    margin-left: 0;

    a,
    .no-link {
      display: inline-block;
      line-height: 1.25;
      font-size: 1.125rem;
      font-weight: $semibold-font-weight;
    }

    .button {
      margin-top: .5rem;
    }
  }

  .ian-ecommerce-cart-desc {
    font-size: .875rem;
    margin-top: .25rem;
  }

  .ian-ecommerce-cart-quantity {
    flex-basis: 10%;
    text-align: right;

    input {
      margin-bottom: 0;
    }

    a {
      font-weight: normal;
      font-size: .875rem;
    }

    .display {
      font-size: 1.125rem;
      font-weight: $semibold-font-weight;
    }
  }

  .ian-ecommerce-cart-price {
    white-space: nowrap;
    flex-basis: 20%;

    .page-checkout & {
      margin-right: 0;
    }
  }
}

.ian-ecommerce-cart-contents {
  .ian-ecommerce-cart-price {
    color: $price-color;
    font-size: 1.125rem;
    font-weight: $semibold-font-weight;
  }

  &:not(:last-of-type) {
    border-bottom: 1px $cart-border-color solid;
  }
}

.ian-ecommerce-cart-header {
  border-bottom: 1px $cart-border-color solid;
  font-size: .875rem;
  font-weight: 700;
}

.ian-ecommerce-cart-total-vat-contents {
  text-align: right;

  strong {
    padding-left: 1rem;
  }
}

.ian-ecommerce-cart-total {
  border-top: 1px $cart-border-color solid;
  text-align: right;
  padding-top: 1.35rem;
  font-size: 1.125rem;
  font-weight: $semibold-font-weight;
  margin-bottom: 3.25rem;

  span {
    color: $price-color;
    display: inline-block;
    padding-left: .5rem;
  }

  .ian-ecommerce-subtotal-price,
  .ian-ecommerce-total-vat,
  .ian-ecommerce-ship-price {
    font-weight: 400;
    margin-bottom: .675rem;

    span {
      color: $body-font-color;
      padding-left: .75rem;
    }
  }

  div + .ian-ecommerce-total-price {
    margin-top: 1rem;
  }
}

.ian-ecommerce-cart-total-price-contents {
  padding-top: 0;
  padding-bottom: 0;
  text-align: right;

  strong {
    font-size: 1.25rem;
    padding-left: 1rem;
  }
}

.ian-ecommerce-checkout-header {
  h3 {
    font-size: 2.25rem;
    font-weight: 500;
    margin-bottom: 0;
  }
  p {
    font-size: .875rem;
    margin-bottom: .5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.details {
    margin-bottom: 1.25rem;
    margin-top: 2.75rem;
  }
  &.shipping {
    margin-top: 2rem;
    margin-bottom: .5rem;
  }
}


.ian-ecommerce-thank-you {
  border-bottom: 1px $cart-border-color solid;
  margin-bottom: 2rem;
}

// Order form fields
.ian-ecommerce-checkout-form {

  .form-item-md-tax-exempt-number {
    margin-top: 1.25rem;
  }

  .form-item {
    margin-bottom: 1.35rem;

    > label {
      font-size: 1.125rem;
      font-weight: $semibold-font-weight;
    }
  }

  .form-actions {
    text-align: right;
  }

  input, select {
    margin-bottom: 0;
    max-width: 100%;
  }

  .description {
    margin-top: .25rem;
  }

  .form-required {
    color: $price-color;
  }
}

// Order details
.ian-ecommerce-order-details {
  margin-bottom: 2rem;

  .ian-panel .ian-main & h4,
  .ian-panel .ian-main & h5 {
    text-align: left;
  }

  .ian-ecommerce-order-date {
    margin-top: -1rem;
    margin-bottom: 2rem;
  }

  .ian-ecommerce-order-customer-payment {
    @include breakpoint(medium) {
      display: flex;

      > * {
        flex-grow: 1;
        margin-right: 20px;
      }
    }
  }
}

// User Page, Order Listing & detail page
.page-ian-user,
.page-ian-user-orders,
.page-ian-user-downloads {
  //background-color: rgba(230, 230, 230, 1);
  //
  //.messages {
  //  max-width: $grid-row-width;
  //}
  //
  //.region-content > .block > .content {
  //  margin-left: auto;
  //  margin-right: auto;
  //  max-width: $grid-row-width;
  //  padding: 2rem;
  //  background-color: #FFF;
  //  margin-bottom: 4rem;
  //}

  // page title - hide.
  .page-title.text-center {
    display: none;
  }

}
