// Default form styles
input, button, select, textarea {
	@include remove-outline;
	@include transition();
}
select {
	background-origin: border-box;
	background-position: right 1rem center;
	cursor: pointer;
	padding: 0 2rem 0 1rem;
}
select[multiple=multiple] {
	padding: 0;
	min-height: 6rem;
	option {
		padding: .5rem 1rem;
		line-height: 3rem;
	}
}
.button {
	@include transition();
	@include kanit_semibold();
	text-transform: uppercase;
	text-decoration: none !important;
	letter-spacing: 1px;
	position: relative;
	width: 100%;
	display: block;
	@include breakpoint(medium) {
		width: auto;
		display: inline-block;
	}
	&.outline {
		border-radius: 0;
		border: 1px solid $white;
		background: transparent;
		color: $white;
		&:hover {
			background: $white;
			color: $dark-gray;
		}
	}
}

.button--print, a.button--print, .button--print a {
	@include opensans_bold;
	font-size: $large-font-size;
	padding: 0;
	text-transform: none;
	background: transparent;
	color: $blue;
	&:before {
		@include fontawesome;
		content: '\f02f';
		margin-right: .5rem;
	}
}

.main .region-content, footer.footer, footer.footer--bottom {
	.button {
		&:not(input) {
			&:after {
				@include transition();
				@include fontawesome;
				color: rgba($white, 0);
				position: absolute;
				content: '\f105';
				height: 1em;
				width: 1em;
			}
			&:hover {
				padding-left: ($button-padding-horizontal - .25) + em;
				padding-right: ($button-padding-horizontal + .25) + em;
				&:after {
					padding-left: .5em;
					color: rgba($white, 1);
				}
			}
			&.outline {
				&:after {
					color: rgba($dark-gray, 0);
				}
				&:hover:after {
					color: rgba($dark-gray, 1);
				}
			}
		}
	}
}

input[type="submit"] {
	@extend .button;
	@extend .primary;
	margin-right: .5rem;
}

// .main content form item overrides
.main {
	input:not([type=submit]), select, textarea {
		border-radius: .25rem;
		border: 1px solid $medium-gray;
		&:focus {
			border: 1px solid $blue;
		}
		// Errors
		&.error {
			border-color: $input-error-color;
		}
	}
	input, select {
		height: 3rem;
		line-height: 3rem;
	}
	input {
		padding: 0 1rem;
	}
	input[type=radio],
	input[type=checkbox] {
		cursor: pointer;
	}
	input[type=radio] {
		height: 1.5rem;
		width: 1.5rem;
		padding: 0;
		margin: .5rem 0;
		border-radius: 100rem;
		&:checked {
			position: relative;
			&:after {
				@include fontawesome;
				color: $blue;
				height: 1rem;
				width: 1rem;
				line-height: 0;
				padding: .70rem .25rem;
				position: absolute;
				content: '\f111';
			}
		}
		+ label {
			margin: .5rem;
			vertical-align: top;
		}
	}
	input[type=checkbox] {
		height: 1.5rem;
		width: 1.5rem;
		padding: 0;
		margin: .5rem 0;
		&:checked {
			position: relative;
			&:after {
				@include fontawesome;
				color: $blue;
				position: absolute;
				content: '\f00c';
				width: 1.5rem;
				height: 1.5rem;
				box-sizing: border-box;
				padding: 0 0.0625rem 0.125rem 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		+ label {
			margin: .5rem;
			vertical-align: top;
		}
	}
	input[type=file] {
		line-height: 0;
		padding: 1rem;
		margin-bottom: 1rem;
		@include breakpoint(medium) {
			margin-right: 1rem;
			max-width: 50%;
		}
	}
	textarea {
		margin-bottom: 1rem;
	}
}

// Webform styles
.webform-component-date {
	select {
		@include breakpoint(medium) {
			max-width: 20%;
		}
		@include breakpoint(large) {
			max-width: 15%;
		}
	}
	input.webform-calendar {
		border: 0;
		padding: 0;
		margin: 0;
		height: auto;
	}
}
.webform-component-time {
	select {
		max-width: 48%;
		@include breakpoint(medium) {
			max-width: 20%;
		}
		@include breakpoint(large) {
			max-width: 15%;
		}
	}
	.form-radios {
		margin-bottom: 1rem;
		height: auto;
		display: inline-block;
		@include breakpoint(medium) {
			display: inline;
			margin: 1rem;
		}
		.form-item {
			display: inline;
			top: .75rem;
			position: relative;
			margin-right: 1rem;
		}
	}
}
.webform-component-textarea .grippie {
	display: none;
}
.webform-component-file input[type=submit] {
	margin-top: -.5rem;
}

.ian-user .pane-ian-user-register-content-type .form-submit__wrapper{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	-ms-flex-pack: end;
	-ms-flex-align: center;
	align-items: center;
	margin: 2.75rem 0 1rem;

	.captcha{
		order: -1
	}
}
