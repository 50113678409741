footer.footer {
	background-color: $dark-gray;
	color: $white;
	a {
		color: $light-green;
		&:hover {
			text-decoration: underline;
		}
		&.button {
			color: $white;
			&:hover {
				text-decoration: none;
			}
		}
	}
	> .row > .columns {
		.block {
			overflow: hidden;
			margin-bottom: 2rem;
			@include breakpoint(medium) {
				margin-bottom: 0;
			}
			h2 {
				@extend h5;
			}
		}
		&:last-of-type {
			.block {
				margin-bottom: 0;
			}
		}
	}
}
.block--footer-contact {
	font-size: 1.2rem;
}
footer.footer--bottom {
	> .row {
		padding: 1rem 0;
	}
	color: $white;
	background: $off-black;
	text-align: center;
	font-size: .9rem;
	text-transform: uppercase;
	a {
		color: $white;
	}
}