.panel--search {
	&.ian-panel {

		.search-header {
			h1, h2, h3, h4 {
				text-align: left;
			}

		}

		.row.ian-search-nav {
			position:relative;
			max-width: 95rem;
			padding: 2em 0 0;
		}

		.pane-apachesolr-result {

		}

		.row.search-body {
			max-width: 90rem;
		}

		#apachesolr-panels-search-form {
			position: relative;
		}

		.pane-apachesolr-form {
			.submit-button,
			.views-submit-button {
				#edit-submit {
					background-color: transparent;
				}

				position: absolute;
				top: 20%;
				right: 0;
				width: 2rem;

				&:after {
					@include fontawesome;
					font-size: 1.35rem;
					content: '\f002';
					position: absolute;
					z-index: 0;
					top: 0;
					right: 1rem;
				}

				input[type=submit].button {
					position: relative;
					z-index: 1;
					background-color: transparent;
					padding: 0;
					margin: 0;
					color: transparent;
					text-indent: -999999px;
					height: 4rem;
					width: 4rem;
				}
			}
		}

		.facetapi-umces-search-facet-nav {
			display: table;
			width: 100%;

			li.leaf {
				padding: 0;
				& > a {
					display: block;
					height: 100%;
					padding: 1.4rem 2.5rem;
					& > span {
						white-space: nowrap;
					}
					@include breakpoint(medium down) {
						padding: 0.7rem 0;
					}
				}
				br {
					line-height: normal;
				}
			}

			a.facetapi-inactive {
				transition: 125ms ease;
				&:hover,
				&:focus {
					background-color: $dark-blue;
				}
			}
		}

		.facetapi-facet-ian-bundle {
			.leaf {
				background: $blue;
				width: 14.28571%;
				text-align: center;

				a {
					text-align: center;
					color: white;
				}

				& > a:hover {
					color: white;
					text-decoration: none;
				}
			}

			.leaf.active {
				background: $white;
				color: $green-hover;

				a {
					display: none;
				}

				@include breakpoint(medium down) {
					font-size: 1.25em;
					& > span {
						line-height: 1rem;
						display: block;
						height: 4.3125rem;
						padding: 0.7rem 0;
					}
				}
			}
		}

		@include breakpoint(medium down) {
			.facetapi-facet-ian-bundle {
				.leaf {
					display: table-row;
					width: 100%;
					a {
						padding: 1.5rem 0;
						font-size: 1.25em;
					}
				}
			}
		}

		.facetapi-facetapi-checkbox-links {
			li {
				list-style: none;
				display: flex;
				align-items: flex-start;

				input[type="checkbox"] {
					margin: 0;
					flex: 0 0 auto;

					&:checked {
						margin-right: 0.5rem;
					}
				}

				a {
					color: $dark-gray;
					font-size: 1rem;
					font-weight: normal;
					line-height: 1.375rem;
					padding-left: .5rem;
				}
			}
		}

		.ian-media-library-file-buttons {
			padding-top: 0;

			@include breakpoint(small down) {
				margin-right: 30px;
			}

			.ian-menu li {
				padding-left: 0;
				padding-right: 0;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;

				&:nth-child(odd) {
					background-color: $green;
				}

				&:nth-child(even) {
					background-color: $blue;
				}

				.download {
					background-color: $green;
				}

				.share {
					background-color: $blue;
				}

				.download,
				.share {
					border-radius: inherit;
					padding: 0;
					width: 2.875rem;
					height: 2.875rem;
					display: flex;
					justify-content: center;
					align-items: center;

					i {
						margin: 0;
						&:before {
							font-size: 1.5rem;
						}
					}
				}
			}
		}

		.pane-ian-search-download-submit {
			button {
				&:nth-child(odd) {
					background-color: $green;
					&:hover,
					&:focus {
						background-color: $green-hover;
					}
				}

				&:nth-child(even) {
					background-color: $blue;
					&:hover,
					&:focus {
						background-color: $dark-blue;
					}
				}
			}

			button, .button,
			.ian-download-all-block-link,
			.ian-ecommerce-cart-block-link {
				height: 3rem;
				font-size: 1rem;
				line-height: 1.5em;
				white-space: nowrap;
				padding: 1em;
				min-width: 195px;

				@include breakpoint(medium ) {
					font-size: .8rem;
					margin: 5px;
					width: 20%;
				}
				@include breakpoint(large) {
					width: 90%;
					margin: 0 0 1rem 0;
				}
			}
			.ian-ecommerce-cart-block-link {
				display: inline-flex;
				justify-content: center;
				align-items: center;
			}
			.ian-download-all-block-link {
				&:hover {
					background-color: $green-hover;
				}
			}

		}

		.search-result {
			&__image {
				&.small-12 {
					@include breakpoint(small) {
						margin-bottom: 0.5rem;
					}
				}
			}
			&-checkbox {
				&:checked {
					background-color: $green;

					&:after {
						color: $white;
					}
				}

				&__container {
					width: 2.5rem;
					max-width: 2.5rem;
				}
			}
		}

		li.search-result {


			h2, h3, h4 {
				text-align: left;
			}

			.title{
				font-size:1.4em;

				a {
					font-weight:600;
				}
			}

			ul.ian-menu > li {
				margin: 0 0.2rem;

			}

			.node-facet-type {
				font-size: 1.125rem;
				font-weight: bold;
				font-style: italic;
			}

			.search-info time {
				font-size: 0.875rem;
				font-weight: bold;
			}

			.media-info {
				padding: .33rem 1rem .33rem 0;

				.columns {
					padding-right: 0;
				}

				.media-type {
					font-weight: bolder;
					font-style: italic;
				}
			}

			.media-info-title {
				padding: 0;
				min-height: 6rem;
			}

			.search-snippet-info {
				time{
					font-size: 0.8em;
					font-weight: bold;
				}
				padding-left: 0;
				@include breakpoint(small down) {
					margin-right: 16px;
				}
			}

			.search-results-image {
				text-align: center;
				background-color: lighten($light-gray, 50%);
				display: block;
				width: 100%;
				height: auto;
				position: relative;
				overflow: hidden;
				padding: 80.37% 0 0 0;

				img {
					width: unset;
					display: block;
					max-width: 100%;
					max-height: 100%;
					position: absolute;
					top: 0;
					margin: auto;
					padding:17px;
					bottom: 0;
					left: 0;
					right: 0;

				}
				.flex-video.widescreen {
					padding-bottom: 0;
				}

				.flex-video{
					position:initial;

				}


			}
		}

		&.grid-layout {
			li.search-result {
				border: 1px solid $medium-gray;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				width: 100%;
				padding: 10px 15px;
			}
		}

		&.list-layout {
			.search-result__body{
				width:100%;
				.field-items{
					width:100%;
				}
			}



			.search-result {
				border: inherit;
				border-bottom: 1px solid $medium-gray;
				padding: 0.625rem 0;
				width: 100%;
				&:last-child {
					border-bottom: none;
				}

				.search-result__image img {
					width:100%;
				}


				@include breakpoint(small) {
					.columns {
						padding-left: 1rem;
						padding-right: 1rem;
					}
				}

				@include breakpoint(large) {
					display: flex;
					&__controls__top {
						display: block;
						width: 2.5rem;
						max-width: 2.5rem;
						height: calc(100% - 4.375rem);
					}
				}
				&__controls__top {
					display: flex;
					justify-content: space-between;
					padding: 0;

					.ian-media-library-file-buttons {
						margin-right: 0;
					}
				}
				&-checkbox__container.columns {
					padding-right: 0;
					display: flex;
					align-items: center;
				}

				&__body {
					display: flex;
					flex-flow: row wrap;
				}
				&__image {
					a {
						width: 100%;
						display: block;
						img {
							width: 100%;
						}
					}

					& ~ .button-row {
						margin-top: 0.5rem;
					}
				}

				& > .row {
					padding: 2rem 0;
				}
				.node-facet-type {
					margin-bottom: 1rem;
				}
				.search-info {
					margin-bottom: 0.5rem;
				}
				.button-row {
					margin-bottom: 1rem;
				}
				.columns{
					padding-left:0px;
				}
			}
		}

		.pane-ian-user-login-button-content-type {
			padding-top: 2rem;

			.pane-content {
				a {
					text-decoration: none;
					margin-right: 1rem;
					margin-bottom: 0;
				}

				.user-link {
					color: $dark-blue;
					font-size: 80%;
				}

				.ian-ecommerce-cart-icon {
					@include transition();
					display: inline-block;
					height: 18px;
					width: 18px;
					vertical-align: middle;
					border-radius: 4px;
					background-size: 18px 18px;
					background-repeat: no-repeat;
					background-position: center center;
					text-indent: -99999rem;
					overflow: hidden;

					padding-left: 0 !important;
					padding-right: 0 !important;

					&:hover {
						&::after {
							display: none;
						}
					}
				}
				a:last-child {
					color: $white;
					padding: 0.9375rem 1.875rem;
				}
			}
		}

		.pane-ian-search-facet-description {
			p.title {
				font-weight: bold;
				margin-bottom: 1em;
			}

			.ian-search-description-display-control {
				padding-left: 2rem;
			}

		}

		.search-text-description-wrapper {
			margin-bottom: 36px;
			border-bottom: 1px solid $medium-gray;
		}

		.ian-page-side-bar {
			.pane-block {
				border: 1px solid $medium-gray;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				padding: 1rem 1.5rem;
				margin-bottom: 20px;

				h3, h4 {
					text-align: left;
					text-transform: uppercase;
					color: $blue;
					font-weight: normal;
					font-size: 1.25rem;
				}

				ul {
					margin-left: 0;
				}
			}
			.facetapi-facetapi-checkbox-links .facetapi-checkbox {
				& > span {
					margin-right: 0.25rem;
					word-break: break-word;
				}
				br {
					display: none;
				}
			}
		}

		.pane-ian-search-query-control-content-type {
			form {
				div.columns {
					align-items: center;
					justify-content: center;
					height: 100%;
					display: flex;
				}
			}

			.form-item-rows {
				display: flex;
				align-items: center;

				label {
					margin-right: 10px;
					font-weight: 600;
					font-size: inherit;
				}

				select {
					margin: 0;
					font-size: .85rem;
					height: 2.4375rem;
					line-height: normal;
				}
			}

			a {
				&.icon {
					margin-right: 15px;
					-webkit-border-radius: 4px;
					-moz-border-radius: 4px;
					border-radius: 4px;
					padding: 0.7rem 1rem;
					background-color: $sea-blue;
				}

				&:nth-child(odd) {
					background-color: $sea-blue;
				}

				.secondary {
					background-color: $prussian-blue;
				}

			}
		}

		.field-name-field-media-ref {
			width: 100%;
		}

		.pager {
			margin: auto;

			ul {
				margin: auto;
			}
		}
	}
}

.page-ian-search {
	.breadcrumbs_white {
		margin-top: 0;
		//background-color: $medium-gray;
	}
}
