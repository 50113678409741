@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ydgpxt');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ydgpxt#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ydgpxt') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ydgpxt') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ydgpxt##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.iconmoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ian_icon {
  &:before {
    content: fa-content($icon-ian_icon);
    color: #fff;
  }
}

.icon-work_with_us_icon {
  &:before {
    content: fa-content($icon-work_with_us_icon);
    color: #fff;
  }
}

.icon-resources_icon {
  &:before {
    content: fa-content($icon-resources_icon);
    color: #fff;
  }
}

.icon-report_cards_icon {
  &:before {
    content: fa-content($icon-report_cards_icon);
    color: #fff;
  }
}

.icon-projects_icon {
  &:before {
    content: fa-content($icon-projects_icon);
    color: #fff;
  }
}

.icon-education_icon {
  &:before {
    content: fa-content($icon-education_icon);
    color: #fff;
  }
}

.icon-blog_icon {
  &:before {
    content: fa-content($icon-blog_icon);
    color: #fff;
  }
}

.icon-grid_icon {
  &:before {
    content: fa-content($icon-grid_icon);
    color: #fff;
  }
}

.icon-list_icon {
  &:before {
    content: fa-content($icon-list_icon);
    color: #fff;
  }
}

.icon-download_icon {
  &:before {
    content: fa-content($icon-download_icon);
    color: #fff;
  }
}

.icon-share_icon {
  &:before {
    content: fa-content($icon-share_icon);
    color: #fff;
  }
}

.icon-cancel_circle {
  &:before {
    content: fa-content($icon-cancel_circle);
  }
}
