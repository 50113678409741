.node-type-ian-blogs{
  .page-title__heading {
    display:none;
  }
  .field-label{
    display:none;
  }

}
.ian-blog-title{
  margin-bottom:-2rem;
}
