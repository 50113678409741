.view-directory {
	.view-header {
		margin-left: 2rem;
		margin-right: 2rem;
		margin-top: -2rem;
		@include breakpoint(medium) {
			margin-left: 0;
			margin-right: 0;
			margin-top: -3rem;
		}
		.button {
			margin-bottom: 4rem;
		}
		.row {
			@include kanit_regular;
			font-size: 1.75rem;
			flex-flow: row nowrap;
			.columns {
				padding-top: .75rem;
				padding-bottom: .75rem;
			}
			.columns:nth-child(1) {
				flex: 1 1 44.5%;
			}
			.columns:nth-child(2) {
				flex: 1 1 27.75%;
			}
			.columns:nth-child(3) {
				flex: 1 1 27.75%;
			}
		}
	}
	.views-row-odd {
		background: $white;
	}
	.view-empty {
		margin: 0 0 3rem 0;
		@include breakpoint(medium) {
			margin-bottom: 4rem;
		}
	}
	h4.teaser__title {
		margin-bottom: .5rem;
		height: auto;
		line-height: 100%;
		overflow: hidden;
	}
	.teaser__image {
		margin: 2rem 0 0 0;
		height: auto;
		img {
			width: 100%;
		}
		@include breakpoint(medium) {
			margin: 0;
			* {
				height: 100%;
				background-size: cover !important;
				object-fit: cover;
				object-position: 50% 50%;
				font-family: 'object-fit: contain; object-position: 50% 50%';
			}
		}
	}
	.teaser__content {
		padding: 2rem 0;
	}
	.field-name-field-area-of-expertise-summary {
		margin: 1rem 0 0 0;
		@include breakpoint(large) {
			margin: 0;
		}
	}
	.field-name-field-title-or-position {
		@include opensans_bold;
		margin-bottom: .5rem;
	}
	.field-name-field-email {
		a {
			color: $blue;
		}
	}
}

.view--member--students {
	.views-row {
		margin-bottom: 2rem;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	.teaser__image {
		img {
			border-radius: 100rem;
			overflow: hidden;
		}
	}
	.teaser__content {
		padding: 0;
		.field-type-email, .field-type-email a {
			font-size: $medium-font-size;
			word-wrap: break-word;
		}
	}
	.teaser__title {
		@include opensans_bold;
	}
}

.view--member--featured-work {
	.orbit-caption a {
		color: $white;
	}
}