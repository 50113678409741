.top-bar {
	position: relative;
	z-index: 5;
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	@include breakpoint(large) {
		flex-flow: row nowrap;
		padding-bottom: 0;
	}
	.logo {
		max-width: 20rem;
	}
	.top-bar-hamburger {
		width: 9%;
		margin-left: auto;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		@include breakpoint(large) {
			opacity: 0;
			width: auto;
		}
	}
	.top-bar-left,
	.top-bar-right {
		float: none;
	}
	.top-bar-left {
		overflow: hidden;
		width: 90%;
		padding-right: 2rem;
		@include breakpoint(large) {
			width: auto;
			padding-right: 0;
			.logo {
				padding-bottom: .625rem;
			}
		}
	}
	.top-bar-right {
		background: $light-gray;
		width: 100%;
		margin: 2rem -2rem -2rem;
		padding: 1rem;
		flex-grow: 1;
		display: flex;
		flex-flow: column wrap;
		flex-direction: column-reverse;
		@include breakpoint(large) {
			background: transparent;
			flex-direction: column;
			width: auto;
			margin: 0;
			padding: 0;
		}
	}

	.top-bar-right__top,
	.top-bar-right__bottom {
		@include breakpoint(large) {
			margin-left: auto;
		}
	}
	.top-bar-right__top {
		display: flex;
		flex-flow: row wrap;
	}
	.top-bar-right__bottom {
		margin-top: 2.75rem;
		@include breakpoint (large) {
			width: calc(100% - 2rem);
		}
		@include breakpoint (xlarge) {
			width: calc(100% - 2rem);
		}
		@include breakpoint (xxlarge) {
			width: calc(100% - 10rem);
		}
	}
}

// Prevent menu flash on page load for mobile.
@include breakpoint(medium down) {
	html.no-js {
		.top-bar .top-bar-right {
			display: none;
		}
	}
}