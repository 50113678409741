// Import fonts
@import 'https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700|Open+Sans:400,400i,600,600i,700,700i';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/v4-shims.min.css';

// Open+Sans:400,600,700
@mixin opensans_regular {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

@mixin opensans_semibold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}

@mixin opensans_bold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
}

// Kanit:300,400,500,600,700
@mixin kanit_light {
	font-family: 'Kanit', sans-serif;
	font-weight: 300;
}

@mixin kanit_regular {
	font-family: 'Kanit', sans-serif;
	font-weight: 400;
}

@mixin kanit_medium {
	font-family: 'Kanit', sans-serif;
	font-weight: 500;
}

@mixin kanit_semibold {
	font-family: 'Kanit', sans-serif;
	font-weight: 600;
}

@mixin kanit_bold {
	font-family: 'Kanit', sans-serif;
	font-weight: 700;
}

// Font Awesome
@mixin fontawesome {
	font-family: 'FontAwesome', sans-serif;
}
