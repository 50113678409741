.publication--teaser{
  padding: 20px 0px;
  border-bottom: 1px solid #e8e8e8;
}


.event--teaser{
    padding: 20px 0px;
    border-bottom: 1px solid #e8e8e8;

  }


.news--teaser{
    padding: 20px 0px;
    border-bottom: 1px solid #e8e8e8;
  }
