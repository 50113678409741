body.node-type-member{
	header.header {
		h1.page-title__heading {
			line-height: 100%;
		}
		.field-name-field-title-or-position {
			@include kanit_regular;
			@include header-size-responsive('h1');
			line-height: 100%;
		}
	}
	.panel--default-layout {
		.main {
			@include breakpoint(medium) {
				padding-right: 2rem;
			}
			h3.pane-title {
				margin-bottom: 1rem;
				text-transform: none;
			}
			.pane-content {
				margin-bottom: 2rem;
			}
		}
		.sidebar {
			padding: 2rem;
			margin-bottom: 2rem;
			background: $blue;
			color: $white;
			h2 {
				@include kanit_bold;
				@include header-size-responsive('h3');
				text-transform: none;
				color: $light-green;
				margin-bottom: 1rem;
				&:after {
					content: '';
				}
			}
			h3.pane-title {
				@include opensans_bold;
				color: $white;
				font-size: $xlarge-font-size;
				text-transform: none;
				margin: 0;
				&:after {
					content: ':';
					margin-left: -3px;
				}
			}
			.panel-separator {
				height: 1rem;
			}
			.pane-content {
				font-size: $xlarge-font-size;
				a {
					color: $white;
				}
			}
			.pane-node-field-building-name + .panel-separator + .pane-node-field-room-number {
				margin-top: -1rem;
			}
		}
		.sidebar--bottom {
			background: $dark-gray;
			h2.pane-title {
				@include kanit_bold;
				@include header-size-responsive('h3');
				color: $light-green;
				margin-bottom: 2rem;
				&:after {
					content: '';
				}
			}
		}
		.field-name-field-cv {
			.file-icon, .file-size {
				display: none;
			}
			a {
				@extend .button;
				@extend .secondary;
			}
		}
		.field-name-field-graduate-education-items,
		.field-name-field-areas-of-expertise,
		.field-name-field-area-of-expertise-summary,
		.field-name-field-education {
			@include opensans_bold;
			font-size: $large-font-size;
		}
		.field-name-field-regularly-offered-courses {
			@include kanit_bold;
			@include header-size-responsive('h4');
		}
		.field-name-field-news {
			> .field-items > .field-item {
				border-bottom: 1px solid $light-gray;
				margin-bottom: 3rem;
				&:last-of-type {
					border-bottom: none;
					margin-bottom: 0;
				}
			}
		}
	}
}