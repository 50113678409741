.pane-ian-media-library-file-buttons-content-type {
  padding-top: 0;
  margin-right: 15px;

  @include breakpoint(small down) {
    margin-right: 30px;
  }
}

.ian-media-libray-table {
  table{
    table-layout:fixed;
  }


  td:first-child {
    font-style: italic;
    border-right: none;
    @include breakpoint(medium up) {
      padding-right: 3rem;
      white-space: nowrap;
    }
  }
  td:last-child {
    font-weight: bold;
    border-left: none;
  }
  td {
    padding: 1.125rem;
    word-wrap: break-word;
    white-space: normal;

  }
}

.node-type-ian-media-library .ian-content-footer .pane-content {
  text-align: center;
}

.ian-panel {
  .field-name-body {
    text-align: center;
  }

  .field-name-field-media-ref {
    display: flex;
    justify-content: center;
  }
}

