.view--news-events--home, .view--news-events--related {
	.views-row {
		display: flex;
		margin-bottom: 2rem;
		@include breakpoint(large) {
			margin-bottom: 0;
		}
	}
}

.view--news-events--home {
	.views-row {
		margin-bottom: 2rem;
	}
	.view-footer {
		margin-top: 0;
		@include breakpoint(medium) {
			margin-top: 2rem;
		}
	}
}