// Windows 8 running IE11
body.win8-ie11 {
	// Social media menu tweaks
	.navigation--social .menu > li > a.button.secondary i {
		line-height: 2.5rem;
	}

  .view-media--block .media--teaser {
    width: 100%;
  }
}

// Windows running IE
body.win-ie {
	// Select box tweaks
	select {
		background-image: url(../img/select--arrow.png);
	}

	// Top bar tweaks
	@include breakpoint(large) {
		.top-bar-right__top {
			width: 588px;
		}
	}
	@include breakpoint(xlarge) {
		.top-bar-right__top {
			width: 638px;
		}
	}
	@include breakpoint(xxlarge) {
		.top-bar-right__top {
			width: 688px;
		}
	}

	// Header tweaks
	header.header {
		.page-title__content--with-photo {
			> .row {
				height: 100%;
			}
		}
		.page-title__photo {
			margin-bottom: 0;
		}
	}

	// Card tweaks
	.card {
		width: 100%;
	}
	a.card__title, a .card__title {
		color: $green;
	  &:hover {
		  color: $green-hover !important;
	  	text-decoration: underline;
  	}
	}
	.card__content {
		padding-top: 1rem;
	}

	// Default arrow background tweaks
	.bg--arrows--light {
		@include breakpoint(large) {
			&:before, &:after {
				border: 1px solid rgba($white, .25);
				outline: 8rem solid $black;
			}
			&:before {
				box-shadow: 1px -1px 0 8rem rgba($white, 0.25);
			}
			&:after {
				box-shadow: -1px 1px 0 8rem rgba($white, 0.25);
			}
		}
	}
	.bg--arrows--dark {
		@extend .bg--arrows--light;
		&:before, &:after {
			border: 1px solid rgba($black, .15);
			outline: 8rem solid $white;
		}
		&:before {
			box-shadow: 1px -1px 0 8rem rgba($black, .15);
		}
		&:after {
			box-shadow: -1px 1px 0 8rem rgba($black, .15);
		}
	}
	.bg--blue--arrows {
		@extend .bg--blue;
		@extend .bg--arrows--light;
		&:before, &:after {
			outline: 8rem solid $blue;
		}
	}
	.bg--white--arrows {
		background-color: $white;
		@extend .bg--arrows--dark;
		&:before, &:after {
			outline: 8rem solid $white;
		}
	}
	.bg--light-gray--arrows {
		@extend .bg--light-gray;
		@extend .bg--arrows--dark;
		&:before, &:after {
			outline: 8rem solid $light-gray;
		}
	}

	// Programs page arrow background override
	&.page-programs .panel--landing-page {
		.bg--blue.lead {
			@extend .bg--blue--arrows;
		}
	}

  .view-media--block .media--teaser {
    width: 100%;
  }

}
