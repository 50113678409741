.view-media--block {
	.views-row {
		display: flex;
		margin-bottom: 2rem;
		width: 100%;
		@include breakpoint(large) {
			margin-bottom: 0;
		}
	}
	.media--teaser {
		margin: 1rem 0;
		a h4 {
			color: $green;
			margin-bottom: 0;
			&:hover {
				color: $green-hover;
				text-decoration: underline;
			}
		}
		.field-name-field-source {
			@include opensans_bold;
			font-size: 90%;
			margin-bottom: 0;
			a {
				color: $blue;
				&:hover {
					color: $blue-hover;
					text-decoration: underline;
				}
			}
		}
		.date {
			margin-bottom: 0;
		}
		.field-name-body {
			p:last-child {
				margin-bottom: 0;
			}
		}
	}
	.more-link {
		padding: 0;
	}
}

.view-media--media {
	.views-row {
		width: 100%;
	}
	.media--teaser {
		margin: 0 0;
		padding: 4rem 0;
		border-bottom: 1px solid $medium-gray;
		a h4 {
			color: $green;
			&:hover {
				color: $green-hover;
				text-decoration: underline;
			}
		}
		.field-name-field-source {
			@include opensans_bold;
			font-size: 90%;
			margin-bottom: 1rem;
			a {
				color: $blue;
				&:hover {
					color: $blue-hover;
					text-decoration: underline;
				}
			}
		}
		.field-name-body {
			p:last-child {
				margin-bottom: 0;
			}
		}
	}
	.views-row:last-of-type {
		.media--teaser {
			border-bottom: none;
		}
	}
}
