.panel--one-column.ian-panel {
  word-wrap: break-word;

.ian-media-url-copy-button {
  height: 16px !important;
  width: 72.8px;
  padding: 1em 0;
}

.pane-node-field-media-ref{
  .field-items{
    width:100%;
  }
}
}



.ian-header {
  padding-top: 4rem;
  .panel-pane > .pane-content > h1, h2 {
    text-align: center;
  }
}

.ian-main {
  ol {
    margin-left: 0;
  }
  @include breakpoint(small down) {
    margin-top: 1rem;
  }
}

.ian-panel {
  background-color: $light-gray;
  #edit-submit {
    background-color: $green;
  }

  .ian-page-main {
    max-width: 95rem;
    * {
      //text-align: center;
    }
    padding: 3rem 4rem;
    background-color: #FFF;
    .pane-title > h2 {
      text-transform: uppercase;

    }
    .panel-pane > .pane-content > h2, h3, h4, h5 {
      text-align: center;
    }
    .panel-pane {
      padding-bottom: 1rem;
    }
    .pane-ian-media-library-file-buttons-content-type,
    .pane-ian-publication-file-buttons-content-type {
      text-align: center;
      .menu {
        display: inline;
      }
    }
  }

  .ian-column-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ian-menu > li {
  padding-right: .75rem;
  padding-left: .75rem;
  background-color: $green;
  display: inline-block;
  a {
    color: #fff;
  }
}

.pane-ian-publication-file-buttons-content-type .ian-menu li,
.pane-ian-media-library-file-buttons-content-type .ian-menu li
{
  &:nth-child(odd) {
    background-color: $green;
  }
  &:nth-child(even) {
    background-color: $blue;
  }
  a {
    width: 40px;
  }
}

.ian-media-libray-table {
  td {
    border: 1px solid rgba(151, 151, 151, 1);
  }
  tbody > tr:nth-child(2n) {
    background-color: #fff;
  }
}

.pane-ian-user-login-button-content-type {
  .pane-content {
    @include breakpoint(medium) {
      margin-left: 60%;
    }
    @include breakpoint(large) {
      margin-left: 70%;
    }

    a {
      text-decoration: none;
      margin-right:15px;
    }
    a.user-link {
      color: $dark-blue;
    }

    .user-link {
      font-size: 80%;
    }

    .ian-ecommerce-cart-icon {
      @include transition();
      display: inline-block;
      height: 18px;
      width: 18px;
      vertical-align: middle;
      border-radius: 4px;
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -99999rem;
      overflow: hidden;

      padding-left: 0 !important;
      padding-right: 0 !important;

      &:hover {
        &::after { display: none;}
      }
    }
  }

}

.node-type-ian-media-library{
  .ian-page-main {
    max-width:unset;
  }
  .field-item{
    max-width:600px;
    margin:auto;
  }
}
