.ian-media-library-file-buttons {
  ul.ian-menu > li {
    padding-left: 0;
    padding-right: 0;
    margin: 0 0.5rem;
    width: 2.875rem;
    height: 2.875rem;
    .secondary.small.icon {
      padding: 0;
    }
  }
}

ul.ian-menu > li {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &:nth-child(odd) {
    background-color: $green;
  }

  &:nth-child(even) {
    background-color: $blue;
  }

  .download {
    background-color: $green;
  }

  .share {
    background-color: $blue;
  }

  .secondary.small.icon {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      margin: 0;
      &:before {
        font-size: 1.5rem;
      }
    }
  }
}
