.navigation--tabs {
	ul {
		margin: 1rem 0;
	}
	li {
		width: 100%;
		display: block;
		@include breakpoint(medium) {
			display: table-cell;
			width: auto;
			padding-right: .5rem;
		}
		&.active {
			a {
				background: $green;
			}
		}
	}
}