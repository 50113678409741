.view--campus--list {
	h2.campus--teaser__title {
		text-align: left;
	}
	.campus--teaser__image {
		margin-bottom: 1rem;
		img {
			width: 100%;
			height: auto;
		}
	}
	.campus--teaser__content {
		.button {
			margin-top: 1rem;
		}
	}
}

.view--campus--list--mobile {
	padding: 2rem;
	h3 {
		margin-bottom: 2rem;
		text-transform: uppercase;
		color: $white;
	}
	h5.card__title {
		margin-bottom: 1rem;
	}
	a.button {
		margin-bottom: 0;
	}
	.views-row {
		margin-bottom: 2rem;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}


.page-campuses{
	.bg--white--arrows{
		display:none;
	}
}
