.view--graduate-education--home {
	overflow: hidden;
	text-align: center;
	@include breakpoint(large) {
		text-align: left;
	}
	.tabs__wrapper {
		padding: 2rem;
		position: relative;
		width: 100%;
		&:after {
			@include css-triangle(1rem, $dark-gray, down);
			position: absolute;
			left: 0;
			right: 0;
			bottom: -.9rem;
			margin: auto;
		}
		@include breakpoint(large) {
			padding: 4rem 0 4rem 4rem;
		}
	}
	.tabs-title {
		margin-bottom: 0;
		h4 {
			font-size: 1.25rem;
			margin: 0;
		}
	}
	.tabs-content {
		padding: 0 1rem;
		@include breakpoint(medium) {
			padding: 0;
		}
		a.button {
			margin-top: 2rem;
			margin-bottom: 0;
		}
	}
	.tabs-panel {
		h4 {
			color: $light-green;
		}
	}
	.tabs-panel--left {
		color: $black;
		background-color: $light-gray;
		padding: 2rem;
		@include box-shadow(0, -5rem, 5rem, -5rem, rgba($black, .25), $inset: true);
		@include breakpoint(large) {
			@include box-shadow(0, 0, 0, 0, transparent);
			position: relative;
			color: inherit;
			background: transparent;
			padding: 2rem 4rem 2rem 4rem;
			&:after {
				@include css-triangle(2.5rem, $dark-gray, right);
				position: absolute;
				z-index: 5;
				right: -2.5rem;
				top: 0;
				bottom: 0;
				margin: auto;
			}
		}
		@include breakpoint(xxlarge) {
			padding: 10rem 4rem 2rem 4rem;
		}
	}
	.tabs-panel--right{
		padding: 0 4rem 4rem;
		@include breakpoint(large) {
			padding: 4rem 4rem 0 4rem;
		}
		@include breakpoint(xxlarge) {
			padding: 0;
		}
	}

	// Ensure that tabs and tab content for this view has a height
	// that fits the parent container.
	@include breakpoint(xxlarge) {
		.view-content > .columns {
			display: flex;
			.tabs-content {
				flex-grow: 1;
				position: relative;
			}
			.tabs-panel {
				position: absolute;
				height: 100%;
				width: 100%;
				&.is-active {
					display: flex;
				}
				> .row {
					width: 100%;
					margin: 0 !important;
				}
			}
		}
	}
}