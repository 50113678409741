#block-umces-user-blocks-umces-user-info, #block-umces-user-blocks-umces-user-login-button{
  background:$medium-gray;
  text-align: right;
  padding: 1em;
  padding-right: 5em;
  .umces-user-block > span{
    padding:10px;
  }
  .user-name a{
    color: $blue;
    text-decoration: none;
  }
  .btn a{
    border: 0.5em $green;
  }
  .bg-green {
    background: $green;
    a{
      color: $white;
      padding:10px;
    }
  }
  .user-name, .logout{
    font-size: small;
  }
}

