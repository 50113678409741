.navigation--secondary {
	width: 100%;
	padding: 1rem 0;
	@include breakpoint(large) {
		width: auto;
		padding: 0;
	}
	.menu {
		> li {
			display: block;
			float: left;
			width: 50%;
			@include breakpoint(large) {
				display: table-cell;
				width: auto;
			}
			> a.button {
				padding: 0 1rem;
				border-radius: 0;
				min-height: 2rem;
				line-height: 2rem;
				margin: 0 .5rem;
				width: auto;
				@include breakpoint(large) {
					padding: 0 2rem;
					margin: 0 0 0 .5rem;
				}
			}
			&:nth-of-type(2) > a.button,
			&:last-of-type > a.button {
				@extend .secondary;
			}
		}
	}
}
