body.page-search {
	background: $light-gray;

	.breadcrumbs {
		li:last-child {
			display: none;
		}
	}

	// Page title overrides
	.page-title__heading {
		display: none;
	}

	.block-system .content {
		max-width: 75rem;
		margin-left: auto;
		margin-right: auto;
		padding: 2rem 0;

		@include breakpoint(large) {
			padding: 4rem 0;
		}
	}
}

.page-ian-process-search{
	.shared-links-list{
		padding-bottom: 2rem;
	}

	.page-title{
		display:none;
	}
}

.shared-links{
	p{
		margin-bottom:1rem;
	}

}

.clear-search-button{
	float:right;
}
