// Default field styles
.field-name-field-content-paragraphs {
	.call-to-action {
		margin-bottom: 2rem;
	}
}

// Node field style overrides
.page-node {
	.field-name-field-campus, .field-name-field-source {
		font-size: $large-font-size;
		margin-bottom: 2rem;
		a {
			color: $blue;
		}
	}
}

.node-type-ian-media-library{
	.field-image img{
		width: unset;
		display: block;
		max-width: 100%;
		max-height: 100%;
		position: absolute;
		top: 0;
		margin: auto;
		bottom: 0;
		left: 0;
		right: 0;
	}


	.field-image{
		text-align: center;
		display: block;
		width: 100%;
		height: auto;
		position: relative;
		overflow: hidden;
		padding: 70% 50% 0 45%;
	}
}

html.js input.form-autocomplete{
	background-image:none;
}

