#panel--ian-two-column-wrapper {
  ul{
    margin:0px;
  }

  li{
    list-style:none;
  }

  .pane-key-contacts{
    .pane-content{
      display:flex;
      flex-wrap:wrap;
    }

    h4{
      width:100%;
      display:block;
    }
  }

  .member{
    display:flex;
    width:320px;
    padding:5px;
    margin:5px;
  }
  .member img{
    border-radius: 100rem;
    overflow: hidden;
  }

  .columns {

  }
  .left {
    .project-header-intro{
      display:none;
    }
     padding-right:35px;
     padding-left:16px;
    //Publications
    .pane-views-panes {
      .pane-title {
        text-align: left;
        margin-bottom: 1rem;
        color: $blue;
      }
    }
    .pane-ian-project-sidebar-list{
      margin-top:50px;
    }
    .ian-project-sidebar-list {

        margin: 0;
        display:flex;
        flex-wrap:wrap;

      li {
        list-style: none;
        padding:0;
        margin: 5px;
        height:50px;
      }
    }

  }
  .right {
    .field-item{
      display:block;
      float:none;
    }

    .field-label{
      float:none;
    }

    .pane-ian-report-card-node-buttons,
    .pane-ian-report-card-buttons {
      .button {
        @include breakpoint(medium) {
          width: 20rem;
        }
        @include breakpoint(large) {
          width: 100%;
        }
      }
    }

    .panel-pane {
      margin-left: 2rem;
      margin-right: 2rem;
      margin-bottom: 1.5rem;
    }
    .panel--ian-two-column__column {
      padding: 1.5rem 0 2.25rem;
      width: 100%;
      font-size: 125%;
    }
    .key-contact-single {
      padding-top: 25px;
      img {
        border-radius: 50%;
        background: grey;
        margin-bottom: 10px;
        width: 100%;
      }
    }
    .pane-ian-project-sidebar-list {
      margin: auto;
    }
    .ian-project-sidebar-list {
      text-align: center;
      margin: auto;
      ul {
        margin: 0;
      }
      li {
        list-style: none;
        background: $green;
        margin: auto auto 15px auto;
        padding: 10px;
        max-width: 55%;
        border-radius: 5px;
      }
    }

    //Report Card
    $rc-letter-height: 8rem;
    .report-grade-letter {
      p {
        font-size: 7rem;
        line-height: $rc-letter-height;
        margin: 0;
        margin-bottom: 0.75rem;
      }
    }

    .report-grade-description {
      p {
        font-size: 1.25rem;
        line-height: 1.5;
      }
    }
    .sidebar-button-wrapper {
      text-align: center;
    }
    .sidebar-button-item {
      padding: 20px 15px;
      max-width: 90%;
    }
  }
  .bg--blue-gray {
    background: #313D41;
    div {
      color: white;
    }
    h3 {
      color: #a7be77;
    }
    a {
      color: $green;
    }
    a:hover {
      color: $green-hover;
    }

    .field-name-field-publication-file {
      a {
        color: $white;
      }
      &:hover {
        color: $white;
      }
    }

    .pane-report-card-buttons {
      ul {
        list-style-type: none;
        margin-left: 0;
      }
    }

    li {
      a {
        color: white;
        &:hover {
          color: $white;
        }
      }
    }

    .sidebar-button-item {
      color: white;
      &:hover {
        color: white;
      }

      li {
        a {
          color: white;
        }
      }
      .date {
        font-weight: 100;
      }
      .field-label {
        color: white;
        font-weight: bold;
      }
    }
    // Report card
    .pane-sidebar-button-item {
      margin: auto;
    }
    .sidebar-button-item {
      border-radius: 5px;
      text-align: center;
      margin: auto;
    }
    .color-blue {
      background: $blue;
    }
    .color-green {
      background: $green;
    }
    .color-white {
      background: ghostwhite;
    }

  }

  .pane-selectable-report-grade {
    h3, h2, h4 {
      color: $light-green;
    }
  }
}
.page-ian {
  .page-title.text-center {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.node-type-publication > .header > .header__content_uncovered_top_menu {
  .page-title.text-center {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

.node-type-publication {
  .field-name-field-publication-file {
    display: flex;
    justify-content: center;
  }
}