.panel--landing-page {
	.bg--white--arrows {
		h2.pane-title {
			text-align: center;
			margin: 3rem 0 0 0;
		}
	}
	.bg--blue.lead {
		clear: both;
		.paragraph--paragraph-with-link {
			@include breakpoint(large) {
				@include grid-column(10);
				@include grid-column-offset(1);
			}
		}
	}
}

// Overrides for programs page
.page-programs .panel--landing-page {
	.bg--blue.lead {
		@extend .bg--blue--arrows;
	}
}