.orbit {
	@include remove-outline;
	height: auto;
	max-height: 100% !important;
}
.orbit-slide {
	max-height: 100% !important;
}
.orbit-container {
	@include remove-outline;
	height: auto !important;
}
.orbit-image {
	img {
		width: 100%;
		height: auto;
	}
}
.orbit-caption {
	@include kanit_bold;
	@include header-size-responsive(h4);
	a {
		color: $white;
	}
}
.orbit-next, .orbit-previous {
	display: none;
}
.orbit-bullets {
	button {
		border: 1px solid $green;
	}
}
@include breakpoint(large){
	.orbit-next, .orbit-previous {
		opacity: 1;
		display: block;
		height: 100%;
		width: 6rem;
		background: $off-black;
		.button-content-wrapper {
			@include transition();
			position: relative;
			display: block;
			height: 4rem;
			width: 4rem;
			border-radius: 50rem;
			border: 1px solid $green;
			i {
				@include transition();
				position: absolute;
				top: 0;
				height: 100%;
				line-height: 3.75rem;
				width: 90%;
			}
		}
		&:hover {
			.button-content-wrapper i {
				color: $green;
			}
		}
	}
	.orbit-next {
		.button-content-wrapper {
			i {
				right: 0;
			}
		}
	}
	.orbit-previous {
		.button-content-wrapper {
			i {
				left: 0;
			}
		}
	}
}