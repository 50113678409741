.view--research-highlights--home {
	&.orbit {
		@include breakpoint(medium) {
			overflow: hidden;
			max-height: 900px !important;
		}
		@include breakpoint(large) {
			max-height: 1100px !important;
		}
		@include breakpoint(xlarge) {
			max-height: 900px !important;
		}
		@include breakpoint(xxlarge) {
			max-height: 700px !important;
		}
	}
	.research-highlight {
		@include breakpoint(large) {
			min-height: 650px;
		}
	}
	.research-highlight__content {
		position: relative;
		@include breakpoint(medium) {
			&:after {
				@include css-triangle(2.5rem, $dark-gray, right);
				position: absolute;
				z-index: 5;
				right: -2.5rem;
				top: 0;
				bottom: 0;
				margin: auto;
			}
		}
	}
	.research-highlight__content__inner {
		height: 100%;
		display: flex;
		flex-flow: column;
		padding: 2rem 2rem 0 2rem;
		@include breakpoint(medium) {
			padding: 4rem 4rem 8rem 4rem;
		}
		a.button {
			margin-top: 2rem;
		}
	}
	.orbit-bullets {
		margin: 2rem 0;
		@include breakpoint(medium) {
			margin: 0;
			position: absolute;
			bottom: 2rem;
			left: 0;
			width: calc(100% / 2);
		}
		@include breakpoint(large) {
			width: calc(100% / 3);
		}
		counter-reset: section;
		button {
			counter-reset: subsection;
			width: 3rem;
			height: 3rem;
			margin: 0 .5rem;
			background-color: transparent;
			border-radius: 50%;
			border: 1px solid transparent;
			position: relative;
			line-height: 3rem;
			&:after {
				@include opensans_bold;
				font-size: 1.2rem;
				position: absolute;
				left: 0;
				top: 0;
				height: 3rem;
				width: 2.9rem;
				counter-increment: section;
				content: counter(section);
			}
			&:hover {
				color: $green;
			}
			&.is-active {
				border: 1px solid $light-gray;
				&:hover {
					border: 1px solid $green;
				}
			}
		}
	}
}

.view--project--teaser-alt {
	.project--teaser-alternate {
		margin: 0 0;
		padding: 4rem 0;
		border-bottom: 1px solid $medium-gray;
		a h4 {
			color: $green;
			&:hover {
				color: $green-hover;
				text-decoration: underline;
			}
		}
	}
	.views-row:last-of-type {
		.project--teaser-alternate {
			border-bottom: none;
		}
	}
}
