.ian-user {
	background-color: $medium-gray;

	.user-login {
		margin: 0 auto;
	}

	.ian-login {
		background-color: #FFF;
		.pane-title > h2{
			text-transform: uppercase;
			margin-bottom: 2.75rem;
		}
		.panel-pane {
			padding-left: 4rem;
			padding-right: 4rem;
		}
	}
	.pane-ian-user-login-content-type {
		padding: 3.75rem 4.75rem;
		> .pane-title {
			line-height: 1em;
		}
		.form-item label {
			font-size: 1.125rem;
			margin-bottom: 0.25rem;
		}
		.form-required {
			display: none;
		}

		.form-item-pass {
			margin-bottom: 0.75rem;
		}

		.form-submit {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 3.125rem;
			padding: 0 2.5rem;
			margin: 0 auto;
		}
	}

	.forgot-password-link {
		margin-bottom: 2.75rem;
		line-height: 1;
	}

	.forgot-password-link > a{
		font-weight: normal;
		font-size: 0.875rem;
	}

	.register-link {
		text-align: center;
		margin-top: 4.5rem;
	}

	.pane-ian-user-register-content-type {
		padding-bottom: 2rem;

		.pane-title {
			padding-top: 3rem;
			.user-subtitle {
				font-family: 'Open Sans', sans-serif;
				font-size: 0.875rem;
				text-transform: none;
			}
		}
		.form-item {
			width: 100%;
			&.form-type-password,
			&.form-type-password-confirm,
			&.form-type-textfield,
			&.form-type-textarea,
			&.form-type-select {
				label, input {
					font-size: 1.125rem;
				}
				input {
					margin-top: 0.25rem;
					height: 3.75rem;
				}
			}

			&-cc-newsletter {
				.description {
					display: none;
				}
			}

		}

		.field-name-field-usage {
			textarea {
				margin-bottom: 0;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
			margin-bottom: 1rem;
		}

		.field-name-field-terms-of-use,
		.form-item-cc-newsletter {
			input, label, .description {
				font-size: 1.125rem;
			}
			label, .description {
				font-weight: bold;
				line-height: 1.5rem;
				margin: 0.5rem;
			}
		}

		.field-type-addressfield legend {
			display: none;
		}

		.form-item-field-terms-of-use-und {
			.description {
				display: inline-block;
				vertical-align: top;
			}
		}
		.form-required {
			color: $red;
		}

		.metatags-form.collapsed {
			width: 100%;
			height: auto;
		}

		.form-submit,
		.form-cancel {
			padding-left: 2.5rem;
			padding-right: 2.5rem;
			margin: 0;
		}
		.form-submit {
			background-color: $green;
			border: 1px solid $green;
			&:hover {
				background-color: $green-hover;
				border-color: $green-hover;
			}

			&__wrapper {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin: 2.75rem 0 1rem;
			}
		}
		.form-cancel {
			color: $dark-gray;
			background-color: $white;
			border: 1px solid $dark-gray;
			margin-right: 1.5rem;
			&:hover {
				color: $dark-gray;
				background-color: $light-gray;
			}
		}
		#edit-cancel {
			&:after {
				color: $dark-gray;
				opacity: 0;
			}
			&:hover:after {
				opacity: 1;
			}
		}

		.form-item-pass {
			&-pass1,
			&-pass2 {
				display: grid;
				grid-template-columns: 50% 50%;
				grid-column-gap: 1.5rem;
				column-gap: 1.5rem;
				grid-row-gap: 0.25rem;
				row-gap: 0.25rem;
				place-items: start start;

				.password-strength,
				.password-confirm {
					float: none;
					width: 100%;
					margin-top: 0;
					grid-row: 2 / span 1;
					grid-column: 2 / span 1;
				}
				label {
					grid-row: 1 / span 1;
					grid-column: 1 / span 1;
				}
				input#edit-pass-pass1,
				input#edit-pass-pass2 {
					margin-top: 0;
					grid-row: 2 / span 1;
					grid-column: 1 / span 1;
					width: 100%;
				}
				> * {
					grid-column: 1 / span 2;;
					width: 100%;
				}

				@include breakpoint(small down) {
					grid-template-columns: 100%;
					.password-strength,
					.password-confirm {
						grid-row: 3 / span 1;
						grid-column: 1 / span 1;
						margin-bottom: 1rem;
					}
				}
			}
		}
	}

	.user-title {
		margin: 2rem 0;
	}
}
