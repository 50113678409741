.view--press-releases {
	.press-release--teaser {
		margin: 0 0;
		padding: 4rem 0;
		border-bottom: 1px solid $medium-gray;
		a h4 {
			color: $green;
			&:hover {
				color: $green-hover;
				text-decoration: underline;
			}
		}
	}
	.views-row:last-of-type {
		.press-release--teaser {
			border-bottom: none;
		}
	}
}

.view--press-releases--recent {
	.views-row {
		display: flex;
		margin-bottom: 2rem;
		@include breakpoint(large) {
			margin-bottom: 0;
		}
	}
}