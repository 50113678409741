.pane-views-panes {
	width: 100%;
}

#panels-ipe-regionid-featured_projects,
.pane-views-panes.pane-projects-ian-research-highlights {
  min-height: 33em;
  @include breakpoint(medium) {
    min-height: initial;
  }
}
