// Style overrides for news, event, and press release nodes
.node-type-news, .node-type-event, .node-type-press-release, .node-type-media-entry, .node-type-ian-newsletter {

	//IAN Newsletter related
	.ian-newsletter-date p{
		font-weight:600;
	}
	.adaptive-image{
		width:100%;
	}

	// Page title overrides
	.page-title {
		@include flex-grid-row;
	}
	.page-title__content, .navigation--tabs, .messages {
		@include breakpoint(large) {
			@include flex-grid-column(10);
			margin: auto;
		}
	}
	.messages {
		padding-left: 3rem;
	}
	.page-title__content{
		text-align: left;
		@include breakpoint(medium) {
			padding: 0 1rem;
		}
	}
	.page-title__heading {
		@include kanit_regular;
		@include header-size-responsive('h2');
		color: $dark-blue;
		line-height: 125%;
		margin-bottom: 1rem;
	}

	// panel overrides
	.panel--default-layout {
		> .row {
			padding-top: 0;
		}
		.main {
			@include breakpoint(medium) {
				padding-right: 2rem;
			}
			h2.pane-title {
				@include kanit_bold;
				@include header-size-responsive('h3');
				margin-bottom: 1rem;
				text-transform: none;
			}

			// field overrides
			.date {
				@include kanit_semibold;
				@include header-size-responsive('h3');
				color: $dark-blue;
			}
		}
	}
}