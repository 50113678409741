.page-home {
	.page-title__sub-heading {
		margin-top: 3rem;
	}
}
.panel--home-page {
	h2 {
		text-align: center;
	}
	@include breakpoint(medium) {
		.research-highlight {
			h2 {
				text-align: left;
			}
		}
	}
	@include breakpoint(large) {
		.tabs__wrapper {
			h2 {
				text-align: left;
			}
		}
	}
}