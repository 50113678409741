// Views with same layout / etc
.view--campus--teaser, .view--project--teaser, .view--program--teaser {
	.campus, .project, .program {
		position: relative;
		overflow: hidden;
		&:hover {
			.campus__background img, .project__background img, .program__background img {
				transform: scale(1.1);
			}
			.campus__title:after, .project__title:after, .program__title:after {
				left: 1rem;
				right: 1rem;
				max-width: 100%
			}
			.campus__hover, .project__hover, .program__hover {
				@include breakpoint(large) {
					opacity: 1;
					max-height: 540px;
				}
			}
		}
	}
	.campus__background, .project__background, .program__background {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			z-index: 5;
			height: 100%;
			width: 100%;
			background: rgba($black, .5);
		}
		img {
			@include transition();
			min-width: 100%;
		}
	}
	.campus__content, .project__content, .program__content {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
	.campus__content-inner, .project__content-inner, .program__content-inner {
		text-align: center;
		margin: 0 2rem;
		height: 100%;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		@include breakpoint(large) {
			margin: 0 3rem;
		}
		@include breakpoint(xlarge) {
			margin: 0 5rem;
		}
		@include breakpoint(xxlarge) {
			margin: 0 7rem;
		}
	}
	.campus__title, .project__title, .program__title {
		color: $white;
		position: relative;
		padding: 0 1rem .5rem 1rem;
		display: inline-block;
		margin: 1rem auto;
		overflow: hidden;
		max-height: 9.5rem;
		@include breakpoint(medium) {
			@include header-size-responsive(h3);
		}
		@include breakpoint(large) {
			@include header-size-responsive(h4);
			max-height: 8.5rem;
		}
		@include breakpoint(xlarge) {
			@include header-size-responsive(h3);
			max-height: 10rem;
		}
		@include breakpoint(xxlarge) {
			@include header-size-responsive(h2);
			max-height: 10rem;
			margin-top: 0;
		}
		&:after {
			@include transition-basics(.25s);
			content: "";
			position: absolute;
			bottom: 0;
			border-bottom: solid 2px $white;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			width: auto;
			max-width: 0;
		}
	}
	.campus__hover, .project__hover, .program__hover {
		.field-name-field-teaser-description {
			position: relative;
			&:after {
				display: inline;
				position: absolute;
				bottom: -1rem;
				content: '...';
				@include breakpoint(medium) {
					display: none;
				}
			}
			.field-items {
				position: relative;
				.field-item {
					overflow: hidden;
					max-height: 4.5rem;
					@include breakpoint(medium) {
						max-height: 9rem;
					}
					@include breakpoint(large) {
						max-height: 6rem;
					}
					@include breakpoint(xlarge) {
						max-height: 6rem;
					}
					@include breakpoint(xxlarge) {
						max-height: 6rem;
					}
				}
			}
		}
		@include breakpoint(large) {
			@include transition(all .75s ease-in-out);
			opacity: 0;
			overflow: hidden;
			max-height: 0;
		}
	}
	a {
		color: $white;
		&.button {
			margin: 2rem auto 0 auto;
		}
	}
}