// Default page title styles.
.page-title {
	margin: 4rem 0 0 0;
}
.page-title__content {
	padding: 0 2rem;
	@include breakpoint(large) {
		position: relative;
		height: auto;
		margin: auto;
		//padding-top: 9rem;
	}
}
.page-title__heading {
	margin-bottom: 0;
}
.page-title__heading--top {
	@include kanit_regular;
	font-size: 2rem;
	display: block;
	@include breakpoint(large) {
		font-size: 2.5rem;
		line-height: 50%;
		letter-spacing: 5px;
	}
}
.page-title__sup-heading {
	@include opensans_semibold;
	font-size: 1rem;
	display: block;
	text-transform: uppercase;
	margin-bottom: 1rem;
	@include breakpoint(large) {
		font-size: 1.35rem;
		line-height: 50%;
	}
}

.page-title__sub-heading {
	@include opensans_semibold;
	font-size: 1rem;
	margin-bottom: 0;
	@include breakpoint(large) {
		font-size: 1.2rem;
	}
}



// Header styles.
header.header {
	@include breakpoint(large) {
		min-height: 10rem;
	}
	background-color: $dark-blue;
	position: relative;
	z-index: 99;
	.header__bg {
		position: relative;
		&:before {
			position: absolute;
			height: 100%;
			width: 100%;
			//background: rgba($black, .2);
			background: linear-gradient(180deg, rgba(45, 57, 66, 0.7525385154061625) 0%, rgba(0,0,0,0) 100%);
			content: '';
		}
		img {
			width: 100%;
			height: auto;
		}
	}
	.header__content {
		display: flex;
		flex-flow: row wrap;
		z-index: 1;
		@include breakpoint(large) {
			flex-flow: column wrap;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	// Page title overrides if page title is inside of header.header.
	.page-title {
		margin: 0;
		color: $white;
		position: relative;
		overflow: hidden;
		width: 100%;
		@include breakpoint(large) {
			flex-grow: 1;
			display: flex;
			flex-flow: column wrap;
		}
	}
	.page-title__content {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 1;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
	}
	.page-title__content--with-photo {
		text-align: center;
		@include breakpoint(medium) {
			text-align: left;
		}
		> .row {
			width: 100%;
		}
	}
	.page-title__photo {
		margin-bottom: 2rem;
		align-self: center;
		@include breakpoint(medium) {
			margin-bottom: 0;
		}
		img {
			border-radius: 100rem;
			overflow: hidden;
			width: 100%;
			max-width: 12rem;
			@include breakpoint(medium) {
				max-width: 100%;
			}
		}
	}
	.page-title__photo-content {
		display: flex;
		flex-flow: column;
		justify-content: center;
	}
}

// Set fixed heights for splash images at each breakpoint.
//
// Note: object-fit works in most browsers but requires
// a polyfill for IE and Safari.
// See: https://github.com/bfred-it/object-fit-images/

header.header {
	.header__bg img {
		background-size: cover !important;
		object-fit: cover;
		object-position: 50% 50%;
		font-family: 'object-fit: contain; object-position: 50% 50%';
	}
}

// Front page splash image configuration.
.front header.header {
	.header__bg, .header__bg img {
		height: 425px;
		@include breakpoint(medium) {
			height: 425px;
		}
		@include breakpoint(large) {
			height: 650px;
		}
		@include breakpoint(xlarge) {
			height: 800px;
		}
		@include breakpoint(xxlarge) {
			height: 950px;
		}
	}
}

// Secondary page splash image configuration.
.not-front header.header {
	.header__bg, .header__bg img {
		height: 250px;
		@include breakpoint(medium) {
			height: 250px;
		}
		@include breakpoint(large) {
			height: 450px;
		}
		@include breakpoint(xlarge) {
			height: 450px;
		}
		@include breakpoint(xxlarge) {
			height: 600px;
		}
	}
}

// Member page splash image configuration.
.not-front.node-type-member header.header {
	.header__bg, .header__bg img {
		height: 450px;
		@include breakpoint(medium) {
			height: 350px;
		}
		@include breakpoint(large) {
			height: 450px;
		}
		@include breakpoint(xlarge) {
			height: 450px;
		}
		@include breakpoint(xxlarge) {
			height: 600px;
		}
	}

}

.page-ian{
	.page-title__sup-heading {
		@include breakpoint(large) {
			margin-top: 90px;
		}
	}
}
