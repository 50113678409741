.view-news--news-events {
	.news--teaser {
		margin: 4rem 0;
		a h4 {
			color: $green;
			&:hover {
				color: $green-hover;
				text-decoration: underline;
			}
		}
	}
	.more-link {
		padding: 0;
	}
}

.view-news--news {
	.news--teaser {
		margin: 0 0;
		padding: 4rem 0;
		border-bottom: 1px solid $medium-gray;
		a h4 {
			color: $green;
			&:hover {
				color: $green-hover;
				text-decoration: underline;
			}
		}
	}
	.views-row:last-of-type {
		.news--teaser {
			border-bottom: none;
		}
	}
}

.view-news--block {
	.views-row {
		display: flex;
		margin-bottom: 2rem;
		@include breakpoint(large) {
			margin-bottom: 0;
		}
	}
}