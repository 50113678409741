.ian-projects-project-dates{
  font-weight:600;
  margin-bottom:20px;
}

.ian-project--teaser{

  border-top: 1px solid #e8e8e8;
  padding:15px 0px;
}

.ian-projects-details-link a{
  margin-bottom:0;
}


.page-ian-projects{
  .page-title{
    display:none;
    opacity:0;
  }
}

.page-ian-news{
  .page-title{
    display:none;
  }
}

