.panel--ian-landing-page {
  #mission{
    p{
      text-align: center;
      font-size: 3em;
    }
  }

  #featured-image {
    @include breakpoint(medium down) {
      min-height: 30rem;
      height: 100vh;
      max-height: 50rem;
      & > * {
        height: 100%;
        & * {
          height: inherit;
        }
        .caption-container {
          height: auto;
        }
      }
      @include breakpoint(small down) {
        max-height: 100vw
      }
    }

    .field-name-field-featured-image,
    .field-name-field-featured-image .field-items,
    .field-name-field-featured-image .field-item,
    .field-name-field-featured-image .field-item img {
      min-height: 100%;
      height: 100%;
      min-width: 100%;
      width: 100%;
    }
    .field-name-field-featured-image .field-item img {
      object-fit: cover;
    }

    .caption-container {
      right: 0;
      @include breakpoint(small down) {
        padding: 1.5rem;
        top: auto;
        left: 0;
        bottom: 0;
        width: 100%;
        transform: none;
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .ian-footer {
    color: $white;
  }

  #icon-menu {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    ul {
      margin: auto;
    }
    li {
      margin-top: 20px;
      display: inline-block;
      width: 13%;
      height: 150px;
      text-align: center;
    }

    i {
      color: $white;
      background: $blue;
      height: 5rem;
      width: 5rem;
      border-radius: 50rem;
      text-align: center;
      font-size: 3rem;
      line-height: 5rem;
      margin-bottom: 1rem;
      margin-right: 0;
      transition: all 0.25s ease-in-out;
    }
    @include breakpoint(medium down) {
      ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: flex-start;
        padding: 0 10%;
      }
      li {
        flex: 0 1 9rem;
        margin: 0 0 1rem;
        height: auto;
      }
      i {
        font-size: 2.5rem;
        line-height: 4rem;
        width: 4rem;
        height: 4rem;
      }
    }
    @include breakpoint(small down) {
      ul {
        padding: 0 2rem;
      }
      li {
        flex: 0 1 8rem;
      }
    }

    a:hover i {
      transform: scale(1.3);
    }
  }
  #content{
    .pane-custom{
      h2{
        text-align: center;
        padding-bottom: 0.5em;
        color:$blue;
      }
    }
  }

}

.page-ian .page-title__heading {
  text-shadow: 0 0 6px rgba($black, 0.5);
}

// fix header on small screans
@include breakpoint(medium down) {
  .page-ian header.header {
    .header__bg {
      height: 21.625rem;
      max-height: 21.625rem;
      overflow: hidden;
      top: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .page-title {
      overflow: visible;

      &__content {
        height: 21.625rem;
        max-height: 21.625rem;
        top: 100%;
      }
    }
  }
  .main {
    //margin-top: 21.625rem;
  }
}

.pane-useful-resources-content-type {
  margin-top: 2.5em;

  h2 {
    color:$blue;
    text-align: center;
    margin-top: 2.5em;
    margin-bottom: 1em;
  }
  ul {
    text-align: center;
    margin: auto;
  }
  li {
    display: inline-block;
    vertical-align: center;
    min-height: 2rem;
    padding: .5rem 2.5rem;
    border-right: 3px solid rgba(0, 0, 0, 0.25);
    @include breakpoint(medium down) {
      padding: 0.2rem 0.5rem;
      border-right: none;
    }
  }
  li:first-of-type {
    padding-left: 0;
  }

  li:last-of-type {
    border: none;
    padding-right: 0;
  }
  a {
    text-align: center;
  }
  @include breakpoint(medium down) {
    ul {
      max-width: 35rem;
      display: grid;
      grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
      grid-column-gap: 0;
      column-gap: 0;
      grid-row-gap: 0.5rem;
      row-gap: 0.5rem;
    }
    li {
      row-span: 1;
      column-span: 1;
      text-align: center;
      &:first-child, &:last-child {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        @include breakpoint(medium down) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  @include breakpoint(medium only) {
    li:nth-child(3) {
      border-right: none;
    }
  }
  @include breakpoint(30rem down) {
    ul {
      grid-template-columns: 50% 50%;
    }
    li:nth-child(even) {
      border-right: none;
    }
  }
}


