body.page-node-done {
	.page-title__heading {
		@include kanit_regular;
		@include header-size-responsive('h2');
		color: $dark-blue;
		line-height: 125%;
		margin-bottom: 1rem;
	}
	.main.row {
		padding-top: 2rem;
		text-align: center;
		div.links a {
			@extend .button;
		}
	}
}