body.osx-safari {
	.top-bar {
		.top-bar-hamburger {
			width: 9%;
		}
		.top-bar-right__bottom {
			@include breakpoint (xxlarge) {
		    width: calc(100% - 6rem);
	    }
		}
	}
}
