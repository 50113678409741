.page-ian-report-cards {
  .page-title__heading {
    text-shadow: 0 0 6px rgba($black, 0.5);
  }

  // fix header on small screans
  @include breakpoint(medium down) {
    > header.header {
      .header__bg {
        height: 100%;
        max-height: 6.625rem;
        overflow: hidden;
        top: 100%;
      }

      .page-title {
        overflow: visible;

        &__content {
          max-height: 6.625rem;
          top: 100%;
        }
      }
    }
    #panel--ian-two-column-wrapper {
      margin-top: 6.625rem;
    }
  }
  @include breakpoint(large) {
    > header.header .page-title__content {
      padding-top: 9rem;
    }
  }

  #panel--ian-two-column-wrapper {
    margin-bottom: 2rem;
    .columns {
      padding-top: 2rem;
    }

    @include breakpoint(large) {
      .right {
        min-width: 25rem;
      }
      .left {
        max-width: calc(100% - 25rem);
      }
    }
    @include breakpoint(medium down) {
      .right {
        padding-top: 0;
      }
    }
    .left {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .pane-ian-report-card-buttons {
      ul, li:last-child, li:last-child a {
        margin-bottom: 0;
      }

      a {
        font-size: 1rem;
        font-weight: normal;
        letter-spacing: 0.03rem;
        line-height: 1.5rem;
        white-space: nowrap;
        padding: 0.75rem 1rem;
      }
    }

    .panel--ian-two-column__column .pane-content p > strong:only-child {
      color: $dark-blue;
      font-size: 2.25rem;
      font-family: 'Kanit-Regular', 'Kanit', sans-serif;
      font-weight: normal;
      line-height: 1.75rem;
    }
  }
}
