.paragraph-featured-image {

  .featured-image-container {
    position: relative;
    height: 800px;
    width: 100%;
    overflow:hidden;
  }
  .caption-container {
    position: absolute;
    right: 1px;
    top: 50%;
    transform: translateY(-50%);

    width: 600px;

    z-index: 100;

    padding: 20px 20px 0px 40px;

    color: white;
    background: rgba(1, 1, 1, .50);
  }
}