// Windows 8 running IE11
body.win-edge {

	// Card tweaks
	a.card__title, a .card__title {
		color: $green;
	  &:hover {
		  color: $green-hover !important;
	  	text-decoration: underline;
  	}
	}

	// Default arrow background tweaks
	.bg--arrows--light {
		@include breakpoint(large) {
			&:before, &:after {
				border: 1px solid rgba($white, .25);
				outline: 8rem solid $black;
			}
			&:before {
				box-shadow: 1px -1px 0 8rem rgba($white, 0.25);
			}
			&:after {
				box-shadow: -1px 1px 0 8rem rgba($white, 0.25);
			}
		}
	}
	.bg--arrows--dark {
		@extend .bg--arrows--light;
		&:before, &:after {
			border: 1px solid rgba($black, .15);
			outline: 8rem solid $white;
		}
		&:before {
			box-shadow: 1px -1px 0 8rem rgba($black, .15);
		}
		&:after {
			box-shadow: -1px 1px 0 8rem rgba($black, .15);
		}
	}
	.bg--blue--arrows {
		@extend .bg--blue;
		@extend .bg--arrows--light;
		&:before, &:after {
			outline: 8rem solid $blue;
		}
	}
	.bg--white--arrows {
		background-color: $white;
		@extend .bg--arrows--dark;
		&:before, &:after {
			outline: 8rem solid $white;
		}
	}
	.bg--light-gray--arrows {
		@extend .bg--light-gray;
		@extend .bg--arrows--dark;
		&:before, &:after {
			outline: 8rem solid $light-gray;
		}
	}

	// Programs page arrow background override
	&.page-programs .panel--landing-page {
		.bg--blue.lead {
			@extend .bg--blue--arrows;
		}
	}

}
