// Grid styles
.row {
	clear: both;
	padding: 2rem 0;
	@include breakpoint(medium) {
		padding: 4rem 0;
	}
	&.full-width {
		max-width: 100%;
		margin: 0;
	}
	&.no-padding {
		padding: 0;
	}
	&.no-margin {
		margin: 0;
	}
	.row {
		max-width: none;
	}
}

// Base styles
h1 {
	@include kanit_semibold;
	text-transform: uppercase;
	margin-bottom: 2rem;
}
.h1 {
	@extend h1;
}
h2 {
	text-transform: uppercase;
	margin-bottom: 3rem;
}
.h2 {
	@extend h2;
}
h3 {
	@include kanit_semibold;
	color: $dark-blue;
}
.h3 {
	@extend h3;
}
h4 {
	@include kanit_bold;
	margin-bottom: 1rem
}
.h4 {
	@extend h4;
}
h5 {
	@include opensans_bold;
	text-transform: uppercase;
}
.h5 {
	@extend h5;
}
h6 {

}
.h6 {
	@extend h6;
}

a {
	color: $green;
	font-weight: bold;
	&:hover {
		color: $green-hover;
		text-decoration: underline;
	}
}

img {
	max-width: 100%;
	height: auto;
}

sup {
	font-size: 55%;
}

.main ul {
	li {
		margin-bottom: .5rem;
		ul {
			margin-top: .5rem !important;
		}
	}
}

// Formatted text fields
.field-type-text-long,
.field-type-text-with-summary {
	text-align:left !important;
	a {
		font-weight: bold;
	}
}

// Background styles
.bg--arrows--light {
	@include breakpoint(large){
		overflow: hidden;
		position: relative;
		&:before, &:after {
			transform: rotate(45deg);
			content: '';
			position: absolute;
			z-index: 0;
			top: calc(50% - 5rem);
			height: 10rem;
			width: 10rem;
			background: transparent;
			border: 1px solid rgba($white, .25);
			outline: 1px solid rgba($white, .25);
			outline-offset: 10rem;
		}
		&:before {
			left: -5rem;
		}
		&:after {
			right: -5rem;
		}
	}
	> * {
		position: relative;
		z-index: 1;
	}
}
.bg--arrows--dark {
	@extend .bg--arrows--light;
	&:before, &:after {
		border: 1px solid rgba($black, .15);
		outline: 1px solid rgba($black, .15);
	}
}
.bg--blue {
	background-color: $blue;
	color: $white;
	h2 {
		color: $light-green;
	}
}
.bg--blue--arrows {
	@extend .bg--blue;
	@extend .bg--arrows--light;
}
.bg--blue--water {
	@extend .bg--blue;
	background-image: url(../img/bg--blue--water.jpg);
	background-position: center top;
}
.bg--dark-blue {
	@extend .bg--blue;
	background-color: $dark-blue;
}
.bg--dark-gray {
	background-color: $dark-gray;
	color: $white;
	h2 {
		color: $light-green;
	}
}
.bg--black {
	background-color: $off-black;
	color: $white;
	h2, h4 {
		color: $light-green;
	}
}
.bg--white--arrows {
	background-color: $white;
	@extend .bg--arrows--dark;
	h2 {
		color: $dark-blue;
	}
}
.bg--light-gray {
	background-color: $light-gray;
	color: $dark-gray;
	h2 {
		color: $dark-blue;
	}
	h4 {
		color: $blue;
	}
}
.bg--light-gray--arrows {
	@extend .bg--light-gray;
	@extend .bg--arrows--dark;
}
.bg--light-gray--trees {
	@extend .bg--light-gray;
	background-image: url(../img/bg--light-gray--trees.jpg);
	background-size: cover;
	background-position: center center;
}
.bg--light-gray--texture {
	@extend .bg--light-gray;
	background-image: url(../img/bg--light-gray--texture.jpg);
	@supports (background-blend-mode: difference) {
		background-color: $light-black;
		background-blend-mode: difference;
	}
	background-size: cover;
	background-position: center center;
}
.bg--img__wrapper {
	position: relative;
	overflow: hidden;
}
.bg--img {
	height: 100%;
	width: 100%;
	img {
		min-height: 100%;
		min-width: 100%;
		background-size: cover !important;
		object-fit: cover;
		object-position: 50% 50%;
		font-family: 'object-fit:contain;object-position:50% 50%'
	}
}

// Name spaces
.more-link {
	padding: 0 2rem;
	@include breakpoint(medium) {
		margin-top: 2rem
	}
}
.ical-link {
	a {
		&:hover {
			text-decoration: none;
		}
		&:before {
			@include fontawesome;
			display: inline;
			margin: 0 .25rem;
			content: '\f271';
		}
	}
}
.gcal-link {
	@extend .ical-link;
}
.no-pad {
	padding: 0;
}
.clearfix {
	@include clearfix;
}
.date {
	@include opensans_bold;
	font-size: $medium-font-size;
	margin-bottom: 1rem;
}
.date__spacer {
	&:before {
		position: relative;
		display: inline;
		content: '/';
		opacity: .25;
	}
}
.caption {
	font-size: $small-font-size;
	margin: .5rem 0;
	a & {
		font-weight: bold;
	}
}
.lead {
	@include opensans_semibold;
}
.description {
	font-size: $small-font-size;
	margin: 0 0 1rem 0;
}
.breadcrumbs__wrapper {
	 padding: 2rem 0;
 }
.breadcrumbs {
	margin-bottom: 0;
	a, a:hover, a:visited {
		color: $white;
	}
}
.breadcrumbs_white {
	margin-top: 1rem;
	.breadcrumbs {
		li, a, a:hover, a:visited {
			color: $off-black;
		}
	}
}
.blockquote {}
.blockquote__quote {
	@include opensans_semibold;
	font-size: 1.2rem;
	position: relative;
	margin: 4rem 0;
	padding: 0 3rem;
	&:before, &:after {
		@include kanit_semibold;
		font-size: 4rem;
		font-style: italic;
		position: absolute;
		content: '"';
	}
	&:before {
		transform: translate(-.5rem, -3rem);
		left: 0;
		top: 0;
	}
	&:after {
		transform: translate(-.5rem, 5rem);
		right: 0;
		bottom: 0;
	}
}
.blockquote__name {
	@include kanit_semibold;
	@include header-size-responsive('h3');
	text-align: right;
}
.blockquote__title {
	@include opensans_bold;
	text-align: right;
	font-size: 1.2rem;
	font-style: italic;
}
.card {
	@include transition();
	background: $white;
	display: flex;
	flex-flow: column;
	@include breakpoint(large){
		&:hover {
			transform: translateY(-2rem);
			@include box-shadow(0, .25rem, .5rem, 0, rgba($black, .25));
		}
	}
	h1, h2, h3, h4, h5, h6 {
		color: $dark-gray;
	}
	&.card--invert {
		background: $dark-gray;
	}
}
.card__image {
	img {
		width: 100%;
		height: auto;
	}
}
a.card__title, a .card__title {
	color: $green;
	&:hover {
		color: $green-hover;
		text-decoration: underline;
	}
}
.card__content {
	padding: 2rem;
}

.call-to-action {
	background: $dark-gray;
	color: $white;
	padding: 2rem;
}
.call-to-action__text {
	@include opensans_semibold;
	@include header-size-responsive(h3);
	text-transform: uppercase;
	display: block;
	margin-bottom: 2rem;
}
.call-to-action__button {
	margin-bottom: 0;
}

.call-to-action--horizontal {
	.field-name-field-content-paragraphs .call-to-action,
	.call-to-action, &.call-to-action {
		background: $dark-blue;
		color: $white;
		padding: 1rem;
		margin: 0;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;

	}
	.call-to-action__text {
		@include kanit_bold;
		@include header-size-responsive(h3);
		text-align: center;
		text-transform: none;
		margin: .5rem;
	}
	.call-to-action__button {
		width: 100%;
		.button {
			margin: .5rem 0;
		}
		@include breakpoint(medium) {
			width: auto;
			.button {
				margin: .5rem;
			}
		}
	}
}

.paragraph--featured-link {
	a {
		text-decoration: none !important;
		.featured-link {
			text-align: center;
			@include breakpoint(medium) {
				text-align: left;
			}
		}
		.featured-link__image i, .featured-link__image i.icon.fontello {
			@include transition();;
			color: $white;
			background: $blue;
			height: 5rem;
			width: 5rem;
			border-radius: 50rem;
			text-align: center;
			font-size: 3rem;
			line-height: 5rem;
			margin-bottom: 1rem;
			@include breakpoint(medium) {
				margin-bottom: 0;
				float: right;
			}
			&:before {
				margin: 0;
			}
		}
		.featured-link__text {
			color: $black;
			margin: .5rem 0 2rem 0;
		}
		.featured-link__title {
			margin: 0;
			color: $green;
		}
		&:hover {
			.featured-link__title {
				color: $green-hover;
				text-decoration: underline;
			}
		}
		&:hover {
			.featured-link__image i {
				transform: scale(1.3);
			}
		}
	}
}
.teaser__image, .teaser__date {
	margin-bottom: 1rem;
	@include breakpoint(large) {
		margin-bottom: 0;
	}
}
.teaser__date {
	@include kanit_regular;
	background: $blue;
	color: $white;
	height: 137px;
	text-align: center;
	text-transform: uppercase;
	display: flex;
	flex-flow: column;
	justify-content: center;
	font-size: 2.5rem;
	line-height: 2.5rem;
}

// Foundation tabs overrides
.tabs {
	.tabs-title {
		border-bottom: 2px solid rgba($light-gray, .5);
		a {
			@include transition();
			text-decoration: none !important;
			color: $white;
			&:hover {
				color: $dark-gray;
			}
		}
		&.is-active {
			border-bottom: 2px solid $green;
			a:hover {
				background: darken($tab-background-active, 5%);
				color: $white;
			}
		}
		&:last-of-type {
			border-bottom: none;
		}
	}
}

// Foundation pagination overrides.
ul.pagination, .main ul.pagination {
	@include opensans_bold;
	margin: 2rem 0 1.5rem 0;
	li {
		@include transition();
		overflow: hidden;
		height: 3rem;
		line-height: 2.5rem;
		margin: 0 .25rem;
		a {
			@include transition();
			&:hover {
				color: $blue-hover;
				text-decoration: none;
			}
		}
	}
	.pager-item, .current {
		@include transition();
		width: 3rem;
		background-color: transparent;
		border-radius: 50%;
	}
	.pager-item {
		border: 1px solid transparent;
	}
	.current {
		border: 1px solid $blue;
	}
}
.alpha-pagination {
	ul.pagination {
		font-size: inherit;
		li {
			@include transition();
			width: 3rem;
			background-color: transparent;
			border-radius: 50%;
			border: 1px solid transparent;
			display: inline-block;
			a {
				padding: 0;
			}
		}
		.current {
			border-color: $blue;
		}
		.inactive {
			opacity: 0.38;
			span {
				display: block;
			}
		}
	}
}

// Foundation tooltips overrides.
.has-tip {
	@include remove-outline;
}
