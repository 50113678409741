.view-events--news-events {
	.event--teaser {
		margin: 4rem 0;
		a h4 {
			color: $green;
			&:hover {
				color: $green-hover;
				text-decoration: underline;
			}
		}
		.field-name-field-campus {
			@include opensans_bold;
			font-size: 90%;
			margin-bottom: 1rem;
			a {
				color: $blue;
				&:hover {
					color: $blue-hover;
					text-decoration: underline;
				}
			}
		}
	}
	.more-link {
		padding: 0;
	}
}

.view-events--events {
	.event--teaser {
		margin: 0 0;
		padding: 4rem 0;
		border-bottom: 1px solid $medium-gray;
		a h4 {
			color: $green;
			&:hover {
				color: $green-hover;
				text-decoration: underline;
			}
		}
		.field-name-field-campus {
			@include opensans_bold;
			font-size: 90%;
			margin-bottom: 1rem;
			a {
				color: $blue;
				&:hover {
					color: $blue-hover;
					text-decoration: underline;
				}
			}
		}
	}
	.views-row:last-of-type {
		.event--teaser {
			border-bottom: none;
		}
	}
}