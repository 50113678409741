.region-search {
	position: relative;
	width: 100%;
	margin-top: 1rem;
	@include breakpoint(large) {
		width: auto;
		margin-top: 0;
		margin-left: .5rem;
	}
	input[type=text] {
		margin: 0;
		max-width: 100%;
		@include breakpoint(large) {
			width: 150px;
		}
		@include breakpoint(xlarge) {
			min-width: 200px;
		}
		@include breakpoint(xxlarge) {
			min-width: 250px;
		}
		padding: 0 .5rem;
		height: 2rem;
		line-height: 2rem;
		border-radius: $button-radius;
	}
	.views-exposed-widgets, .views-exposed-widget, .views-widget {
		margin: 0;
		width: 100%;
	}
	.submit-button,
	.views-submit-button {
		position: absolute;
		top: 0;
		right: 0;
		width: 2rem;
		&:after {
			@include fontawesome;
			font-size: 1.25rem;
			content: '\f002';
			position: absolute;
			z-index: 0;
			top: 0;
			right: .25rem;
		}
		input[type=submit].button {
			position: relative;
			z-index: 1;
			background-color: transparent;
			padding: 0;
			margin: 0;
			color: transparent;
			text-indent: -999999px;
			height: 2rem;
			width: 2rem;
		}
	}
}