.navigation--primary {
	// Link defaults
	a {
		border-bottom: 1px solid rgba($medium-gray, .5);
		text-transform: uppercase;
		text-decoration: none !important;
		letter-spacing: 0;
		color: $body-font-color;
		@include breakpoint(large) {
			@include kanit_semibold();
			font-size: $small-font-size;
			color: $white;
			border: none;
		}
		@include breakpoint(xlarge) {
			font-size: $medium-font-size;
		}
		@include breakpoint(xxlarge) {
			font-size: $global-font-size;
			letter-spacing: 1px;
		}
	}

	// Top level link styling
	> ul > li, > div.is-drilldown > ul > li {
		position: relative;
		display: block;
    margin-right: 12px;
    white-space: nowrap;
		> a {
			padding: 1rem;
			@include breakpoint(large) {
				padding: .5rem 1rem;
				&:after {
					@include transition-basics(.25s);
					content: "";
					position: absolute;
					bottom: 0;
					border-bottom: solid 2px $white;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					width: auto;
					max-width: 0;
				}
				&:hover:after {
					left: 0;
					right: 0;
					max-width: 100%
				}
			}
		}
	}

	// Accordion menu styles
	ul.menu.accordion-menu {
		li, li.active {
			> a, > a:link, > a:visited, > a:hover, > a:active {
				color: $black;
			}
		}
	}
	ul.menu.is-accordion-submenu {
		padding-left: 2rem;
		font-size: .8rem;
		border-bottom: 1px solid rgba($medium-gray, .5);
		a {
			padding: 1rem;
		}
		li:last-of-type {
			a {
				border: none;
			}
		}
	}

	// Drilldown menu styles
	ul.menu.drilldown {
		li, li.active {
			@include remove-outline();
			> a, > a:link, > a:visited, > a:hover, > a:active {
				@include remove-outline();
				color: $black;
			}
		}
	}
	ul.menu.is-drilldown-submenu {
		position: relative;
		font-size: .8rem;
		border-bottom: 1px solid rgba($medium-gray, .5);
		overflow: hidden;
		height: 0;
		&.is-active {
			height: 100%;
			margin-top: -3rem;
		}
		a {
			padding: 1rem;
		}
		li {
			margin-left: 2rem;
			&:last-of-type {
				a {
					border: none;
				}
			}
			&.js-drilldown-back {
				margin-left: 0;
				a {
					height: 3rem;
					color: transparent !important;
					border: none;
					&:before {
						display: none;
					}
				}
			}
		}
	}

	// Dropdown menu styles
	@include breakpoint(large) {
		> ul {
			width: 100%;
			display: inline-flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			> li {
				&.is-dropdown-submenu-parent:after {
					transition-delay: 0s;
					position: absolute;
					bottom: -1rem;
					padding-top: 1rem;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					opacity: 0;
					@include css-triangle(10px, $blue, up);
					max-width: 0;
				}
				&.is-dropdown-submenu-parent:hover:after {
					transition-delay: 0s;
					opacity: 1;
					max-width: 100%
				}
				> a {
					padding: .5rem 0;
				}
			}
		}
		.is-dropdown-submenu {
			background-color: $blue;
			border-radius: 3px;
			overflow: hidden;
			margin: 1rem 0 1rem 1rem;
			border: 1px solid $blue;
			> li {
				> a {
					@include kanit_regular();
					text-transform: none;
					padding-left: 2rem;
					padding-right: 2rem;
					border-bottom: 1px solid rgba($white, .25);
					&:hover {
						background-color: $white;
						color: $blue;
					}
				}
				&:last-of-type > a {
					border-bottom: none;
				}
			}
		}
	}
}
