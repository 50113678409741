.view--research-areas--home {
	.view-content {
		padding: 0 .5rem;
		@include breakpoint(medium) {
			padding: 0 1rem;
		}
	}
	.views-row {
		padding: 0 .5rem;
		margin-bottom: 1rem;
		@include breakpoint(medium) {
			padding: 0 1rem;
			margin-bottom: 2rem;
		}
		@include breakpoint(large) {
			flex: 0 0 20% !important;
			max-width: 20% !important;
			padding: 0 .5rem;
			margin-bottom: 1rem;
		}
	}
	.research-area {
		position: relative;
		overflow: hidden;
		&:hover {
			.research-area__background img {
				transform: scale(1.1);
			}
			.research-area__title:after {
				left: 1rem;
				right: 1rem;
				max-width: 100%
			}
		}
	}
	.research-area__background {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			z-index: 5;
			height: 100%;
			width: 100%;
			background: rgba($black, .45);
		}
		img {
			@include transition();
			min-width: 100%;
		}
	}
	.research-area__content {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
	.research-area__content-inner {
		height: 100%;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		text-align: center;
	}
	.research-area__title {
		position: relative;
		padding: .5rem 1rem;
		display: inline-block;
		margin: auto;
		&:after {
			@include transition-basics(.25s);
			content: "";
			position: absolute;
			bottom: 0;
			border-bottom: solid 2px $white;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			width: auto;
			max-width: 0;
		}
	}
	a, a .research-area__title {
		color: $white;
	}
}