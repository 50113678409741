.view, .view-content {
	width: 100%;
}

.views-exposed-form {
	text-align: center;
	.views-exposed-widgets, .views-exposed-widget, .views-widget, label {
		width: 100%;
		padding: 0;
		@include breakpoint(medium) {
			width: auto;
			display: inline-block;
			margin: 0 .25rem;
		}
	}
	.views-exposed-widget {
		.form-submit {
			margin-top: 0;
		}
	}
	label {
		@include opensans_regular;
		font-weight: normal;
		font-size: $global-font-size;
	}

}

#views-exposed-form-directory-directory-pane {
  .views-exposed-form {
    .description {
      text-align: left;
      margin-left: 0;
      @include breakpoint(medium) {
        margin-left: 5.4em;
      }
    }
  }
}
